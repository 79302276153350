import { useState } from "react";
import { getCountries, isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../Buttons";
import { PasswordInput, SelectInput, TelephoneInput, TextInput, NumberInput } from "../Inputs";
import { useRegistrationStore } from "../../context";
import { useAlert, useApi } from "../../hooks";
import constants from "../../utils/constants";
import { isValidEmail, isValidPassword } from "../../utils/helpers";
import { formatPhoneNumber } from "react-phone-number-input";
import {InfoTooltip} from "../Displays";

const FOUR_DAYS = 5760;
const SEVEN_DAYS = 10080;

export default function SystemTenantRegistrationForm({ handleSubmitCallback}){
    const { setRegistrationSysTenant, registrationUser, registrationAccount } = useRegistrationStore();
    const alert = useAlert();
    const [name, setName] = useState(registrationAccount?.name ? `${registrationAccount.name} Dev`: "");
    const [adminEmail, setAdminEmail] = useState(registrationAccount?.email || "");
    const [adminMobilePhone, setAdminMobilePhone] = useState(registrationAccount?.mobilePhone || "");
    const [delinquentGracePeriodMinutes, setDelinquentGracePeriodMinutes] = useState(FOUR_DAYS);
    const [warnRenewalMinutesThreshold, setWarnRenewalMinutesThreshold] = useState(SEVEN_DAYS);
    const [warnCancellationMinutesThreshold, setWarnCancellationMinutesThreshold] = useState(SEVEN_DAYS);
    const [processRenewalMinutesThreshold, setProcessRenewalMinutesThreshold] = useState(FOUR_DAYS);
    const [getTenantUsersUri, setGetTenantUsersUri] = useState("");
    const [tenantUserCommunicationSentUri, setTenantUserCommunicationSentUri] = useState("");
    const [clientCommunicationSignature, setClientCommunicationSignature] = useState("");

    function isValid(){
        if (!name || !name.trim()) {
            alert("Invalid input", "Please enter a valid name", "warning");
            return false;
        }
        if (!adminEmail || !adminEmail.trim() || !isValidEmail(adminEmail)) {
            alert("Invalid input", "Please enter a valid email", "warning");
            return false;
        }
        if (!adminMobilePhone || !adminMobilePhone.trim()) {
            alert("Invalid input", "Please enter a valid name", "warning");
            return false;
        }
        if (
            processRenewalMinutesThreshold < 0 ||
            processRenewalMinutesThreshold > 43200
        ) {
            alert(
                "Invalid input",
                "Renewal hours must be between 0 and 43200 (1 month)",
                "warning",
            );
            return false;
        }
        if (!getTenantUsersUri || !getTenantUsersUri.trim()) {
            alert("Invalid input", "Please enter a value for Get Tenant Users Uri", "warning");
            return false;
        }
        return true;
    }

    function handleSubmit(){
        if (!isValid()) return;
        let sysTenantInfo = {
            name: name,
            adminEmail: adminEmail,
            adminMobilePhone: adminMobilePhone,
            delinquentGracePeriodMinutes: delinquentGracePeriodMinutes,
            warnRenewalMinutesThreshold: warnRenewalMinutesThreshold,
            warnCancellationMinutesThreshold: warnCancellationMinutesThreshold,
            processRenewalMinutesThreshold: processRenewalMinutesThreshold,
            getTenantUsersUri: getTenantUsersUri || "",
            tenantUserCommunicationSentUri: tenantUserCommunicationSentUri,
            clientCommunicationSignature: clientCommunicationSignature
        };
        setRegistrationSysTenant(sysTenantInfo);
        handleSubmitCallback(sysTenantInfo);
    }

    return (
        <div className="w-full space-y-6">
            <div className="text-primaryColor text-lg font-semibold flex flex-row justify-start border-b">Tenant Information</div>
            <div>
                <TextInput
                    label="Name"
                    name="name"
                    value={name}
                    maxLength={200}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div>
                <NumberInput
                    id={"grace-tool-tip"}
                    label="Delinquent Grace Period Minutes"
                    name="delinquentGracePeriodMinutes"
                    value={delinquentGracePeriodMinutes}
                    onChange={e => {setDelinquentGracePeriodMinutes(e.target.valueAsNumber)}}
                    min={0}
                />
                <InfoTooltip id={"#grace-tool-tip"}>
                    {constants.EXPLANATIONS.Grace}
                </InfoTooltip>
            </div>
            <div>
                <NumberInput
                    id={"warn-renew-tool-tip"}
                    label="Warning of Renewal Minutes Threshold"
                    name="warningRenewalHours"
                    value={warnRenewalMinutesThreshold}
                    onChange={e => {setWarnRenewalMinutesThreshold(e.target.valueAsNumber)}}
                    min={0}
                />
                <InfoTooltip id={"#warn-renew-tool-tip"}>
                    {constants.EXPLANATIONS.WarnRenewal}
                </InfoTooltip>
            </div>
            <div>
                <NumberInput
                    id={"warn-cancel-tool-tip"}
                    label="Warning of Cancellation Minutes Threshold"
                    name="warningCancellationHours"
                    value={warnCancellationMinutesThreshold}
                    onChange={e => {setWarnCancellationMinutesThreshold(e.target.valueAsNumber)}}
                    min={0}
                />
                <InfoTooltip id={"#warn-cancel-tool-tip"}>
                    {constants.EXPLANATIONS.WarnCanel}
                </InfoTooltip>
            </div>
            <div>
                <NumberInput
                    id={"renew-tool-tip"}
                    label="Process Renewal Minutes Threshold"
                    name="processRenewalMinutesThreshold"
                    value={processRenewalMinutesThreshold}
                    onChange={e => {setProcessRenewalMinutesThreshold(e.target.valueAsNumber)}}
                    min={0}
                    required
                />
                <InfoTooltip id={"#renew-tool-tip"}>
                    {constants.EXPLANATIONS.Renew}
                </InfoTooltip>
            </div>
            <div>
                <TextInput
                    id={"email-tool-tip"}
                    label="Admin Email"
                    name="adminEmail"
                    value={adminEmail}
                    maxLength={150}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    required
                />
                <InfoTooltip id={"#email-tool-tip"}>
                    {constants.EXPLANATIONS.EmailNotification}
                </InfoTooltip>
            </div>
            <div>
                <TextInput
                    id={"phone-tool-tip"}
                    label="Admin Mobile Phone"
                    name="adminMobilePhone"
                    value={adminMobilePhone}
                    maxLength={25}
                    onChange={(e) => setAdminMobilePhone(e.target.value)}
                    required
                />
                <InfoTooltip id={"#phone-tool-tip"}>
                    {constants.EXPLANATIONS.MobileNotification}
                </InfoTooltip>
            </div>
            <div>
                <TextInput
                    id={"tenant-users-tool-tip"}
                    label="Get Tenant Users Uri"
                    name="getTenantUsersUri"
                    value={getTenantUsersUri}
                    maxLength={300}
                    onChange={(e) => setGetTenantUsersUri(e.target.value)}
                />
                <InfoTooltip id={"#tenant-users-tool-tip"}>
                    {constants.EXPLANATIONS.GetTenantUsers}
                </InfoTooltip>
            </div>
            <div>
                <TextInput
                    id={"communication-tool-tip"}
                    label="Tenant User Communication Sent Uri"
                    name="tenantUserCommunicationSentUri"
                    value={tenantUserCommunicationSentUri}
                    maxLength={300}
                    onChange={(e) => setTenantUserCommunicationSentUri(e.target.value)}
                />
                <InfoTooltip id={"#communication-tool-tip"}>
                    {constants.EXPLANATIONS.CommunicationSent}
                </InfoTooltip>
            </div>
            <div>
                <TextInput
                    id={"signature-tool-tip"}
                    label="Client Communication Signature"
                    name="clientCommunicationSignature"
                    value={clientCommunicationSignature}
                    maxLength={300}
                    onChange={(e) => setClientCommunicationSignature(e.target.value)}
                />
                <InfoTooltip id={"#signature-tool-tip"}>
                    {constants.EXPLANATIONS.Signature}
                </InfoTooltip>
            </div>
            <PrimaryButton text="SUBMIT" onClick={handleSubmit} />
        </div>
    );
}
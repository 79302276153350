import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput, PasswordInput, ToggleInput } from "../components/Inputs";
import { ModalWrapper } from "../components/Modals";
import { PageHeader } from "../components/Layouts";
import { TabView } from "../components/Tabs";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue, resolveSystemTenantId, switchSortDirection, isDev } from "../utils/helpers";
import { UserCircleIcon, ChatBubbleBottomCenterTextIcon, QueueListIcon, BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import { ActiveFilter, PrimaryTable } from "../components/Tables";
import dateHelpers from "../utils/dateHelpers";

const EMPTY_FILTERS = {
  activeOnly: false,
};

export default function SystemTenant() {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post } = useApi();
  const queryTab = searchParams.get('tab');
  const { PROFILE, ACTIVITY, NOTIFICATIONS } = constants.SYS_TENANT_TABS;
  const { setShowLoader } = useLoaderStore();
  const { currentUser, activeSystemTenant } = useUserStore();

  //SystemTenant Variables
  const [systemTenant, setSystemTenant] = useState({ id });
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [activeTab, setActiveTab] = useState(queryTab || PROFILE);
  const [freeDefaultProductLicenseIdData, setFreeDefaultProductLicenseIdData] = useState([]);
  
  //Activity Variables
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    ...EMPTY_FILTERS,
    activeOnly: true,
  });
  const [stagedFilters, setStagedFilters] = useState(activeFilters);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const [activities, setActivities] = useState({ list: [], totalCount: 0 });
  const [notifications, setNotifications] = useState({ list: [], totalCount: 0 });
  const IS_DEV = isDev();

  useEffect(() => {
    if(isDirty) {
      setShowResetConfirmation(true);
    }
    else {
      getSystemTenant();
    }
  }, [showFullDetails]);

  useEffect(() => {
    if (isCreatingNew) return;
    fetch("admin/getProductsSimple", { sortField: "Name", systemTenantId: id })
       .then((res) => {
           setFreeDefaultProductLicenseIdData(res);
       });
  }, [])

  useEffect(() => {
    if (activeTab === ACTIVITY || activeTab === NOTIFICATIONS) {
      setCurrentPage(1);
    }
  }, [activeTab])

  useEffect(() => {
    getActivityList();
    getNotificationList();
  }, [currentPage, displayCount, activeFilters, sortDirection, sortField]);

  const getNotificationList = () => {
    if (isCreatingNew) return;
    setIsLoading(true);
    fetch("admin/NotificationList", {
      pageNumber: currentPage,
      pageSize: displayCount,
      sortDirection,
      sortField,
      isExact: false,
      systemTenantId: id,
      ...activeFilters,
    })
      .then((res) => {
        setNotifications(res);
      })
      .catch((err) => {
        alert("Server error", "Could not retrieve products", "error");
      })
      .finally(() => setIsLoading(false));
  };

  const getActivityList = () => {
    if (isCreatingNew) return;
    setIsLoading(true);
    fetch("admin/ApiOperationsList", {
      pageNumber: currentPage,
      pageSize: displayCount,
      sortDirection,
      sortField,
      isExact: false,
      systemTenantId: id,
      ...activeFilters,
    })
      .then((res) => {
        setActivities(res);
      })
      .catch((err) => {
        alert("Server error", "Could not retrieve products", "error");
      })
      .finally(() => setIsLoading(false));
  };

  function getSystemTenant() {
    if (isCreatingNew) return;
    setShowLoader(true);
    fetch(`admin/systemTenantInfo/${id}/${showFullDetails}`)
      .then((res) => {
        setSystemTenant(res);
        setIsDirty(false);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve systemTenant", "error");
      })
      .finally(() => setShowLoader(false));
  }

  const handleChange = (property, value) => {
    setSystemTenant({
      ...systemTenant,
      [property]: value,
    });
    if(!isDirty){
      setIsDirty(true);
    }
  };

  const handleCredentialChange = (property, value) => {
    let copy = {
      ...systemTenant
    };
    if(!copy.credentials) copy.credentials = {};
    copy.credentials[property] = value;
    setSystemTenant(copy);
    if(!isDirty){
      setIsDirty(true);
    }
  };

  const handleSave = (reloadSystemTenant = false) => {
    if (!isValid() || (!activeSystemTenant && isCreatingNew)) return;

    setShowLoader(true);
    let payload = Object.assign({}, systemTenant);
    if (isCreatingNew) {
      payload.accountId = activeSystemTenant.accountId;
    }
    let saveCredentials = showFullDetails && systemTenant.credentials !== null;
    post(`admin/saveSystemTenant/${saveCredentials}`, payload)
      .then((res) => {
        alert("Success", "Saved systemTenant", "success");
        if (reloadSystemTenant) {
          //set id to the newly saved system tenant id
          if(isCreatingNew && res.id) id = res.id;
          getSystemTenant();
        }
        else {
          navigate("/systemTenants");
        }
      })
      .catch((err) => {
        alert(
          "Unable to save systemTenant",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!systemTenant.name || !systemTenant.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!systemTenant.processRenewalMinutesThreshold) {
      alert("Form invalid", "Process Renewal Hours Threshold must have a value", "warning");
      return false;
    }
    if (!systemTenant.adminEmail || !systemTenant.adminEmail.trim()) {
      alert("Form invalid", "Admin Email must have a value", "warning");
      return false;
    }
    if (!systemTenant.adminMobilePhone || !systemTenant.adminMobilePhone.trim()) {
      alert("Form invalid", "Admin Mobile Phone must have a value", "warning");
      return false;
    }
    if (!systemTenant.getTenantUsersUri || !systemTenant.getTenantUsersUri.trim()) {
      alert("Form invalid", "Get Tenant Users Uri must have a value", "warning");
      return false;
    }
    // if (!systemTenant.tenantUserCommunicationSentUri || !systemTenant.tenantUserCommunicationSentUri.trim()) {
    //   alert("Form invalid", "Tenant User Communication Sent Uri must have a value", "warning");
    //   return false;
    // }
    //ALL CREDENTIALS ARE OPTIONAL
    // if(showFullDetails) {
    //   if (!systemTenant.credentials?.stripeApiKey || !systemTenant.credentials?.stripeApiKey.trim()) {
    //     alert("Form invalid", "Stripe Api Key must have a value", "warning");
    //     return false;
    //   }
    //   if (!systemTenant.credentials?.smtpMailFromAddress || !systemTenant.credentials?.smtpMailFromAddress.trim()) {
    //     alert("Form invalid", "SMTP Mail From Address must have a value", "warning");
    //     return false;
    //   }
    //   if (!systemTenant.credentials?.smtpMailFromName || !systemTenant.credentials?.smtpMailFromName.trim()) {
    //     alert("Form invalid", "SMTP Mail From Name must have a value", "warning");
    //     return false;
    //   }
    //   if (!systemTenant.credentials?.smtpServer || !systemTenant.credentials?.smtpServer.trim()) {
    //     alert("Form invalid", "SMTP Server must have a value", "warning");
    //     return false;
    //   }
    //   if (!systemTenant.credentials?.smtpPort) {
    //     alert("Form invalid", "SMTP Port must have a value", "warning");
    //     return false;
    //   }
    //   if (typeof systemTenant.credentials?.smtpServerRequiresAuthentication !== 'boolean') {
    //     alert("Form invalid", "SMTP Server Requires Authentication must have a value", "warning");
    //     return false;
    //   }
    //   if (typeof systemTenant.credentials?.smtpServerRequiresSsl !== 'boolean') {
    //     alert("Form invalid", "SMTP Server Requires Ssl must have a value", "warning");
    //     return false;
    //   }
    //   if (!systemTenant.credentials?.smtpServerPassword || !systemTenant.credentials?.smtpServerPassword.trim()) {
    //     alert("Form invalid", "SMTP Server Password must have a value", "warning");
    //     return false;
    //   }
    // }

    return true;
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !systemTenant ? "Create new systemTenant" : `Manage systemTenant ${IS_DEV ? systemTenant.id : ""}`
        }
      />
      <GoBack display="Manage all systemTenants" navigateTo="/systemTenants" />
      <div className="flex flex-col lg:flex-row justify-between">
        <TabView 
          tabs={[
            {name: PROFILE, icon: BuildingStorefrontIcon},
            {name: ACTIVITY, icon: QueueListIcon},
            {name: NOTIFICATIONS, icon: ChatBubbleBottomCenterTextIcon},
          ]}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
        {activeTab == PROFILE && (
        <div className="flex flex-row">
          <ToggleInput
            // label={"Show credentials"}
            enabled={showFullDetails}
            setEnabled={setShowFullDetails}
          />
          <span className="ml-3">Show credentials</span>
        </div>)}
      </div>
      {activeTab === PROFILE && (
        <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
          {/* Form */}
          <CollapsibleSection title="Info">
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
              <div>
                <TextInput
                  label="Name"
                  value={systemTenant?.name ?? ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                  required
                  maxLength={200}
                />
              </div>
              <div>
                <SelectInput
                  label="Free Default Product License Id"
                  options={convertToLabelValueList(freeDefaultProductLicenseIdData)}
                  onClear={() => handleChange("freeDefaultProductLicenseId", undefined)}
                  value={convertToLabelValue(freeDefaultProductLicenseIdData?.find(x => x.id === systemTenant?.freeDefaultProductLicenseId))}
                  onChange={(freeDefaultProductLicenseId) => handleChange("freeDefaultProductLicenseId", freeDefaultProductLicenseId.value)}
                  isSearchable
                />
              </div>
              <div>
                <NumberInput
                  label="Delinquent Grace Period Minutes"
                  value={systemTenant?.delinquentGracePeriodMinutes ?? ""}
                  onChange={(e) => handleChange("delinquentGracePeriodMinutes", e.target.valueAsNumber)}
                />
              </div>
              <div>
                <NumberInput
                  label="Warn Renewal Minutes Threshold"
                  value={systemTenant?.warnRenewalMinutesThreshold ?? ""}
                  onChange={(e) => handleChange("warnRenewalMinutesThreshold", e.target.valueAsNumber)}
                />
              </div>
              <div>
                <NumberInput
                  label="Warn Cancellation Minutes Threshold"
                  value={systemTenant?.warnCancellationMinutesThreshold ?? ""}
                  onChange={(e) => handleChange("warnCancellationMinutesThreshold", e.target.valueAsNumber)}
                />
              </div>
              <div>
                <NumberInput
                  label="Process Renewal Minutes Threshold"
                  value={systemTenant?.processRenewalMinutesThreshold ?? ""}
                  onChange={(e) => handleChange("processRenewalMinutesThreshold", e.target.valueAsNumber)}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Admin Email"
                  value={systemTenant?.adminEmail ?? ""}
                  onChange={(e) => handleChange("adminEmail", e.target.value)}
                  required
                  maxLength={150}
                />
              </div>
              <div>
                <TextInput
                  label="Logo Uri"
                  value={systemTenant?.logoUri ?? ""}
                  onChange={(e) => handleChange("logoUri", e.target.value)}
                  maxLength={300}
                />
              </div>
              <div>
                <TextInput
                  label="Admin Mobile Phone"
                  value={systemTenant?.adminMobilePhone ?? ""}
                  onChange={(e) => handleChange("adminMobilePhone", e.target.value)}
                  required
                  maxLength={25}
                />
              </div>
              <div>
                <TextInput
                  label="Get Tenant Users Uri"
                  value={systemTenant?.getTenantUsersUri ?? ""}
                  onChange={(e) => handleChange("getTenantUsersUri", e.target.value)}
                  required
                  maxLength={300}
                />
              </div>
              <div>
                <TextInput
                  label="Tenant User Communication Sent Uri"
                  value={systemTenant?.tenantUserCommunicationSentUri ?? ""}
                  onChange={(e) => handleChange("tenantUserCommunicationSentUri", e.target.value)}
                  maxLength={300}
                />
              </div>
              <div>
                <TextInput
                  label="Tenant Subscription Changed Uri"
                  value={systemTenant?.tenantSubscriptionChangedUri ?? ""}
                  onChange={(e) => handleChange("tenantSubscriptionChangedUri", e.target.value)}
                  maxLength={300}
                />
              </div>
              <div>
                <TextInput
                  label="Client Communication Signature"
                  value={systemTenant?.clientCommunicationSignature ?? ""}
                  onChange={(e) => handleChange("clientCommunicationSignature", e.target.value)}
                  maxLength={300}
                />
              </div>
            {/* Put fields here */}
            </div>
          </CollapsibleSection>
          {showFullDetails && (
            <CollapsibleSection title="Credentials" startOpen={showFullDetails}>
              <div className="flex flex-col gap-y-4">
                <div>
                  <h3 className="py-2 px-4 font-semibold">Stripe</h3>
                  <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 border-b-2">
                    <div>
                      <PasswordInput
                        label="Stripe Api Key"
                        value={systemTenant?.credentials?.stripeApiKey ?? ""}
                        onChange={(e) => handleCredentialChange("stripeApiKey", e.target.value)}
                        required
                        maxLength={150}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="py-2 px-4 font-semibold">Twilio/SMS</h3>
                  <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 border-b-2">
                  <div>
                    <PasswordInput
                      label="Twilio Account Sid"
                      value={systemTenant?.credentials?.twilioAccountSid ?? ""}
                      onChange={(e) => handleCredentialChange("twilioAccountSid", e.target.value)}
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="Twilio Auth Token"
                      value={systemTenant?.credentials?.twilioAuthToken ?? ""}
                      onChange={(e) => handleCredentialChange("twilioAuthToken", e.target.value)}
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="Twilio Send From Number"
                      value={systemTenant?.credentials?.twilioSendFromNumber ?? ""}
                      onChange={(e) => handleCredentialChange("twilioSendFromNumber", e.target.value)}
                      maxLength={25}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="Twilio Send From Message Sid"
                      value={systemTenant?.credentials?.twilioSendFromMessageSid ?? ""}
                      onChange={(e) => handleCredentialChange("twilioSendFromMessageSid", e.target.value)}
                      maxLength={100}
                    />
                  </div>
                  </div>
                </div>
                <div>
                  <h3 className="py-2 px-4 font-semibold">Email</h3>
                  <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 border-b-2">
                  <div>
                    <PasswordInput
                      label="SMTP Mail From Address"
                      value={systemTenant?.credentials?.smtpMailFromAddress ?? ""}
                      onChange={(e) => handleCredentialChange("smtpMailFromAddress", e.target.value)}
                      required
                      maxLength={150}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="SMTP Mail From Name"
                      value={systemTenant?.credentials?.smtpMailFromName ?? ""}
                      onChange={(e) => handleCredentialChange("smtpMailFromName", e.target.value)}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="SMTP Server"
                      value={systemTenant?.credentials?.smtpServer ?? ""}
                      onChange={(e) => handleCredentialChange("smtpServer", e.target.value)}
                      required
                      maxLength={150}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="SMTP Port"
                      value={systemTenant?.credentials?.smtpPort ?? ""}
                      onChange={(e) =>{
                        let value = Number.parseInt(e.target.value, 10);
                        if(Number.isNaN(value) && e.target.value) return;
                        if(Number.isNaN(value) && !e.target.value) value = null;
                        handleCredentialChange("smtpPort", value);
                        }}
                      required
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="SMTP Server Username"
                      value={systemTenant?.credentials?.smtpServerUsername ?? ""}
                      onChange={(e) => handleCredentialChange("smtpServerUsername", e.target.value)}
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <PasswordInput
                      label="SMTP Server Password"
                      value={systemTenant?.credentials?.smtpServerPassword ?? ""}
                      onChange={(e) => handleCredentialChange("smtpServerPassword", e.target.value)}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <CheckboxInput
                      label="SMTP Server Requires Authentication?"
                      checked={systemTenant?.credentials?.smtpServerRequiresAuthentication}
                      onChange={(e) => handleCredentialChange("smtpServerRequiresAuthentication", !systemTenant?.credentials?.smtpServerRequiresAuthentication)}
                    />
                  </div>
                  <div>
                    <CheckboxInput
                      label="SMTP Server Requires Ssl?"
                      checked={systemTenant?.credentials?.smtpServerRequiresSsl}
                      onChange={(e) => handleCredentialChange("smtpServerRequiresSsl", !systemTenant?.credentials?.smtpServerRequiresSsl)}
                    />
                  </div>
                  </div>
                </div>
              </div>
            </CollapsibleSection>
          )}
          {/* Submit button */}
          <div className="mt-12 pb-4 px-4">
            <PrimaryButton
              text={isCreatingNew ? "Create" : "Save"}
              onClick={handleSave}
            />
          </div>
          <div>
            <ModalWrapper open={showResetConfirmation} setOpen={setShowResetConfirmation}>
              <div className="w-[85vw] lg:w-[35vw]">
                <div className="pb-4 text-center flex items-center flex-col">
                  {/* <Icon className="w-5 text-gray-500" /> */}
                  Unsaved changes
                </div>
                <div className="pb-8 mx-6 text-center text-sm text-gray-500">
                  {`You have unsaved changes. Would you like to save them before ${showFullDetails ? "loading" : "hiding"} credentials.`}
                </div>
                <div className="mt-3 flex flex-col lg:flex-row justify-between gap-2">
                  <PrimaryButton
                    text={"Yes"}
                    onClick={() => {
                      setShowResetConfirmation(false);
                      handleSave(true);
                    }}
                  />
                  <PrimaryButton
                    text={"No"}
                    onClick={() => {
                      setShowResetConfirmation(false);
                      getSystemTenant();
                    }}
                  />
                </div>
              </div>
            </ModalWrapper>
          </div>
        </div>
      )}
      {activeTab === ACTIVITY &&(
        <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
          <PrimaryTable
            isLoading={isLoading}
            totalCount={activities?.totalCount}
            displayCount={displayCount}
            currentPage={currentPage}
            setDisplayCount={setDisplayCount}
            setCurrentPage={setCurrentPage}
            header={
              <PrimaryTable.Header>
                <PrimaryTable.HeaderColumn
                  display="Id"
                  // onSort={() => handleSort("id")}
                  isSortingBy={sortField === "id"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Http Method"
                />
                <PrimaryTable.HeaderColumn
                  display="Uri"
                  onSort={() => handleSort("uri")}
                  isSortingBy={sortField === "uri"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Initiated At"
                  onSort={() => handleSort("initiatedAt")}
                  isSortingBy={sortField === "initiatedAt"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Completed At"
                  onSort={() => handleSort("completedAt")}
                  isSortingBy={sortField === "completedAt"}
                  sortDirection={sortDirection}
                />
              </PrimaryTable.Header>
            }
          >
            {activities &&
              activities.list &&
              activities.list.map((x, i) => (
                <PrimaryTable.Row
                  key={`${x.id}-${i}`}
                  index={i}
                  onClick={(e) => {
                    if (!x.deactivatedAt) {
                      navigate(`/activity/${id}/${x.id}`);
                    }
                  }}
                >
                  <PrimaryTable.Cell>
                    {x.id}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {x.httpMethod}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {x.operationUri}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {dateHelpers.localDateTime(x.initiatedAt, dateHelpers.YMDHMSA)}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {dateHelpers.localDateTime(x.completedAt, dateHelpers.YMDHMSA)}
                  </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
          </PrimaryTable>
        </div>
      )}
      {activeTab === NOTIFICATIONS &&(
        <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
          <PrimaryTable
            isLoading={isLoading}
            totalCount={notifications?.totalCount}
            displayCount={displayCount}
            currentPage={currentPage}
            setDisplayCount={setDisplayCount}
            setCurrentPage={setCurrentPage}
            header={
              <PrimaryTable.Header>
                <PrimaryTable.HeaderColumn
                  display="Id"
                  onSort={() => handleSort("id")}
                  isSortingBy={sortField === "id"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Tenant"
                  onSort={() => handleSort("tenant")}
                  isSortingBy={sortField === "tenant"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Email"
                  onSort={() => handleSort("email")}
                  isSortingBy={sortField === "email"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Phone"
                  onSort={() => handleSort("phone")}
                  isSortingBy={sortField === "phone"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Method"
                  onSort={() => handleSort("notificationMethod")}
                  isSortingBy={sortField === "notificationMethod"}
                  sortDirection={sortDirection}
                />
                <PrimaryTable.HeaderColumn
                  display="Sent At"
                  onSort={() => handleSort("sentAt")}
                  isSortingBy={sortField === "sentAt"}
                  sortDirection={sortDirection}
                />
              </PrimaryTable.Header>
            }
          >
            {notifications &&
              notifications.list &&
              notifications.list.map((x, i) => (
                <PrimaryTable.Row
                  key={`${x.id}-${i}`}
                  index={i}
                  onClick={(e) => {
                    if (!x.deactivatedAt) {
                      navigate(`/notification/${id}/${x.id}`);
                    }
                  }}
                >
                  <PrimaryTable.Cell>
                    {x.id}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {x.tenantName}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {x.email}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {x.mobilePhone}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {x.notificationMethod}
                  </PrimaryTable.Cell>
                  <PrimaryTable.Cell>
                    {dateHelpers.localDateTime(x.sentAt, dateHelpers.YMDHMSA)}
                  </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
          </PrimaryTable>
        </div>
      )}
    </div>
  );
}

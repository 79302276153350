import Skeleton from "react-loading-skeleton";
import PaymentMethod from "./PaymentMethod";
import { CreditCardIcon } from "@heroicons/react/24/solid";
import constants from "../../utils/constants";
import AddNewPaymentMethod from "./AddNewPaymentMethod";

export default function DefaultPaymentMethod({
  defaultPaymentMethod,
  existingPaymentMethodCount,
  isLoading,
  setPaymentMethodModalOpen,
}) {
  return (
    <div className="p-6 rounded-lg border bg-white border-gray-200 shadow-sm h-full flex flex-col justify-between">
      <div className="">
        <div>Payment Method</div>
        <div className="italic text-gray-500 mb-2">
          Default payment method that will be used for the next payment
        </div>
        <div className="w-full xl:w-2/3 2xl:w-1/2">
          {isLoading ? (
            <Skeleton height="160px" />
          ) : defaultPaymentMethod ? (
            <PaymentMethod paymentMethod={defaultPaymentMethod} forShowOnly />
          ) : existingPaymentMethodCount < constants.MAX_PAYMENT_TYPES ? (
            <AddNewPaymentMethod
              setPaymentMethodModalOpen={setPaymentMethodModalOpen}
            />
          ) : (
            <div className="flex flex-col justify-center items-center text-center bg-gray-50 rounded-lg min-h-[10rem] p-4">
              <CreditCardIcon className="w-16 h-16 text-gray-300" />
              <div className="text-gray-500">
                Set a current payment method as your default below
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-end text-gray-500">
        Change default payment method below
      </div>
    </div>
  );
}
import { useMemo, useState } from "react";
import ModalWrapper from "./ModalWrapper";
import { NumberInput, SelectInput, TextInput } from "../Inputs";
import constants from "../../utils/constants";
import { formatCurrency } from "../../utils/helpers";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "../Buttons";

function RefundConfirmationModal({
    modalOpen,
    title,
    message,
    textToMatch,
    confirmButtonText,
    setModalOpen,
    onConfirm,
    productPrice
  }) {
    const [userInput, setUserInput] = useState("");
    const [refundAmount, setRefundAmount] = useState();
    const [refundReason, setRefundReason] = useState();
  
    const userInputMatches = useMemo(
      () => textToMatch === userInput,
      [textToMatch, userInput]
    );
  
    return (
      <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
        <div className="w-[85vw] lg:w-[35vw]">
          <div className="pb-4 text-center flex items-center flex-col">
            {title}
          </div>
          {message && (
            <div className="pb-8 mx-6 text-center text-sm text-gray-500">
              {message}
            </div>
          )}
  
          <div className="mb-8">
            <TextInput
              label={"Enter username to confirm"}
              placeholder={textToMatch}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              iconColor={userInputMatches ? "success" : "notification"}
              Icon={userInputMatches ? CheckIcon : ExclamationTriangleIcon}
            />
          </div>
          <div className="mb-8">
            <SelectInput
                label={"Refund reason (optional)"}
                placeholder={constants.REFUND_REASONS[0].label}
                options={constants.REFUND_REASONS}
                value={refundReason}
                onChange={(reason) => {setRefundReason(reason)} }
                onClear={() => {setRefundReason(undefined)}}
            />
          </div>
          <div className="mb-8">
            <NumberInput
              placeholder={productPrice}
              label={"Enter refund amount as pennies"}
              value={refundAmount}
              onChange={(e) => setRefundAmount(isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
              iconColor={userInputMatches ? "success" : "notification"}
              Icon={userInputMatches ? CheckIcon : ExclamationTriangleIcon}
            />
          </div>
          {refundAmount && refundAmount !== 0 
          ? (<div className="mb-8">
                <span>Amount: </span>
                <span>{formatCurrency(refundAmount, true)}</span>
            </div>) 
          : null}
  
          <div className="mt-3">
            <PrimaryButton
              text={confirmButtonText}
              onClick={() => {onConfirm(refundAmount, refundReason?.value)}}
              disabled={!userInputMatches}
            />
          </div>
        </div>
      </ModalWrapper>
    );
}

export default RefundConfirmationModal;
import { useState } from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { TextInput } from "../components/Inputs";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import { isValidEmail } from "../utils/helpers";
import { useNavigate } from "react-router";


export default function ForgotPassword() {
  const alert = useAlert();
  const { post } = useApi();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { setShowLoader } = useLoaderStore();


  const handleSubmit = async () => {
    if (!isValid()) return;

    setShowLoader(true);

    await post("public/forgotPassword", email)
    .then((res) => {
      alert("Success!", "Check your inbox for a reset password email", "success", false);
      navigate("/");
    })
    .catch((err) => {
      alert("Unsuccessful", "We could not send a reset password email", "error");
      console.error(err);
    })
    .finally(() => {
      setShowLoader(false);
    });
    
  };

  const isValid = () => {
    if (!email || !email.trim() || !isValidEmail(email)) {
      alert("Invalid input", "Please enter a valid email", "warning");
      return false;
    }

    return true;
  };

  return (
    <>
      <h1 className="text-3xl font-bold text-primaryColor">Forgot Password</h1>
      <h5 className="mt-1 mb-6">
        Or{" "}
        <Link to="/" className="text-primaryColor hover:text-primaryColor/50">
          back to sign in
        </Link>
      </h5>

      <div className="w-full space-y-6">
        <div>
          <TextInput
            label="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit();
            }}
          />
        </div>
        <PrimaryButton text="Send Reset Password Email" onClick={handleSubmit} />
      </div>
    </>
  );
}

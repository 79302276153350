import { create } from "zustand";
import constants from "../utils/constants";

const useRegistrationStore = create((set, get) => ({
    registrationUser: undefined,
    registrationSysTenant: undefined,
    registrationAccount: undefined,
    currentStep: constants.REGISTRATION_STEPS.USER,
    setRegistrationUser: (registrationUser) =>  set(() => ({ registrationUser })),
    setRegistrationSysTenant: (registrationSysTenant) => set(() => ({ registrationSysTenant })),
    setRegistrationAccount: (registrationAccount) => set(() => ({ registrationAccount })),
    setCurrentStep: (currentStep) => set(() => ({ currentStep }))
}));

export default useRegistrationStore;
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Label } from ".";
import { classNames } from "../../utils/helpers";

export default function TextInput({
  label,
  subLabel,
  name,
  placeholder = "",
  required = false,
  value,
  maxLength,
  iconColor = "primaryColor",
  Icon,
  onChange,
  onClear,
  onKeyDown,
  onBlur,
  onFocus,
  onClick,
  onKeyUp,
  id
}) {
  return (
    <div id={id}>
      {/* Label/required asterisk */}
      {label && <Label label={label} required={required} />}
      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        {/* Input */}
        <input
          type="text"
          name={name}
          className={classNames("p-2 rounded-md  focus:outline-none block w-full sm:text-sm border border-gray-200 placeholder-gray-400", !onChange ? "bg-gray-200" : "")}
          placeholder={placeholder}
          required={required}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          readOnly={!onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          autoComplete="off"
        />

        {/* Icon */}
        {Icon && (
          <Icon
            className={classNames(
              `w-5 absolute right-0 mr-2 my-auto inset-y-0 text-${iconColor}`
            )}
          />
        )}
        {/* X icon if clearable */}
        {onClear && value && (
          <XMarkIcon
            className="h-4 w-4 text-gray-400 absolute right-0 mr-2 my-auto inset-y-0 cursor-pointer"
            onClick={onClear}
          />
        )}
      </div>
      {/* Sublabel */}
      {subLabel && (
        <div className="mt-1 text-gray-400 text-xs text-right">{subLabel}</div>
      )}
    </div>
  );
}

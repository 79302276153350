import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Loader, Notification } from "./components/Alerts";
import { AuthLayout, PublicLayout } from "./components/Layouts";
import { useUserStore } from "./context";
import {
  Dashboard,
  Demo,
  EmailVerification,
  ForgotPassword,
  Login,
  NotFound404,
  Profile,
  Register,
  ResetPassword,
  User,
  Users,
  Product,
  Products,
  SystemTenantApiKey,
  SystemTenantApiKeys,
  SystemTenant,
  SystemTenants,
  Payment,
  Payments,
  OwnedProducts,
  Activity,
  Notification as NotificationPage,
  Account,
  SystemAccounts,
  TransactionReporting,
  AccountProfile,
  AnalyticsPage
} from "./pages";
import { JsonTryParse } from "./utils/helpers";
import storage from "./utils/storage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  //import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || ""
  "pk_test_51OcCpVKS7DupIgmojiZnZYbKh719wfuRUbK6EEW9fVZTyDrjpyJg8PiYV5HuGX6RaOiVMcSXFpkb6NUeIf5Hlzzk001wFzksAr"
  // "pk_live_51OcCpVKS7DupIgmokb3hc5ntWy6GQsc9Rxa7mafxTeh85gDpGb307Ld61ybZhUAxmh3DNxh6WktDTHupU4Eje8tQ00CdisD0r0"
);

function App() {
  const { currentUser, setCurrentUser, setImpersonating, setActiveSystemTenant, setSystemTenants, setActiveAccount } = useUserStore();

  useEffect(() => {
    setCurrentUser(JsonTryParse(storage.getItem(storage.ITEMS.currentUser)));
    setImpersonating(!!storage.getItem(storage.ITEMS.storageCurrentUser));
    setSystemTenants(JsonTryParse(storage.getItem(storage.ITEMS.systemTenants)));
    let storageSystemTenant = JsonTryParse(storage.getItem(storage.ITEMS.activeSystemTenant));
    if (storageSystemTenant) {
      setActiveSystemTenant(storageSystemTenant);
      setActiveAccount({
        id: storageSystemTenant?.accountId, 
        name: storageSystemTenant?.accountName
      });
    }
  }, [setCurrentUser, setImpersonating, setActiveSystemTenant, setSystemTenants]);

  return (
    <div className="w-full h-screen mx-auto bg-backgroundColor overflow-x-hidden overflow-y-auto">
      <Notification />
      <Loader />
      <Elements stripe={stripePromise}>
      {currentUser ? (
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<User />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<Product />} />
            <Route path="/ownedProducts" element={<OwnedProducts />} />
            {/* <Route path="/products/:id" element={<Product />} /> */}
            <Route path="/systemTenantApiKeys" element={<SystemTenantApiKeys />} />
            <Route path="/systemTenantApiKeys/:id" element={<SystemTenantApiKey />} />
            <Route path="/systemTenants" element={<SystemTenants />} />
            <Route path="/systemTenants/:id" element={<SystemTenant />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/payments/:id" element={<Payment />} />
            <Route path="/activity/:systemTenantId/:id" element={<Activity />} />
            <Route path="/notification/:systemTenantId/:id" element={<NotificationPage />} />
            <Route path="/account" element={<Account />} />
            <Route path="/systemAccounts" element={<SystemAccounts />} />
            <Route path="/transactionReporting" element={<TransactionReporting />} />
            <Route path="/systemAccounts/:id" element={<AccountProfile />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="*" element={<NotFound404 />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
            <Route path="/resetPassword/:token/:redirectToMarkt" element={<ResetPassword />} />
            <Route path="/verifyEmail/:token" element={<EmailVerification />} />
            <Route path="/verifyEmail/:token/:redirectToMarkt" element={<EmailVerification />} />
            <Route path="*" element={<NotFound404 />} />
          </Route>
        </Routes>
      )}
      </Elements>
    </div>
  );
}

export default App;

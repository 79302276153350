import { classNames } from "../../utils/helpers";

export default function TabButton({ name, onClick, selected, tabIcon }) {
    return (
    //   <a
        
        
    //     className={classNames(
    //       "",
    //       "group   px-1 text-sm font-medium  gap-x-1",
    //       selected ?  "text-primaryColor border-primaryColor" : ""
    //     )}
        
    //   >
        <div 
            key={name}
            onClick={onClick}
            aria-current={selected ? "page" : undefined}
            className={classNames("flex items-center px-2 py-4 border-b-2 text-gray-500 cursor-pointer text-base", selected ?  "text-primaryColor border-primaryColor" : "")}
        >
          {tabIcon}
          <span>{name}</span>
        </div>
    );
  }
import { CheckIcon } from "@heroicons/react/24/solid";
import { useRegistrationStore } from "../../context";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";

function RegistrationStepsHeader() {
    const { currentStep, setCurrentStep } = useRegistrationStore();
    const STEPS = constants.STEPS_LIST;
    function handleStepChange(nextStep) {
      if(nextStep.name !== currentStep.name) {
        setCurrentStep(nextStep);
      }
    }
  
    return (
      <nav aria-label="Progress" className={"w-full"}>
        <div
        //   role="list"
          className="border border-gray-300 xs:flex md:divide-y-0 flex flex-row"
        >
          {STEPS.map((step, stepIdx) => {
            const isCurrent = step.name === currentStep.name;
  
            return (
              <div key={step.name} className={classNames("relative xs:flex xs:flex-1 grow cursor-pointer")}>
                <StepDisplay step={step} isCurrent={isCurrent} handleStepChange={handleStepChange} />
  
                {stepIdx !== STEPS.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div
                      className="absolute right-0 top-0 hidden h-full w-5 md:block"
                      aria-hidden="true"
                    >
                      <svg
                        className={classNames("h-full w-full text-gray-300", (isCurrent ? "text-primaryColor" : null))}
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      </nav>
    );
  }

function StepDisplay({step, isCurrent, handleStepChange}) {
    return (<div
        onClick={() => handleStepChange(step)}
        // className={classNames(isCurrent ? "border-b border-primaryColor" : "")}
    >
      <span
        className={classNames(
          "flex items-center px-2 md:px-6 py-4 text-sm md:text-md  font-medium",
        )}
      >
        <span
          className={classNames(
            "flex h-6 w-6 sm:h-10 sm:w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ",
            (isCurrent ? "border-primaryColor bg-primaryColor" : "border-gray-300")
          )}
        >
          {isCurrent ? (
            <CheckIcon
              className="h-3 w-3 md:h-6 md:w-6 text-white"
              aria-hidden="true"
            />
          ) : (
            <span className="text-gray-500 cursor-default h-1/2 w-1/2 flex flex-wrap justify-center content-center">
              {step.seq}
            </span>
          )}
        </span>
        <span
          className={classNames(
            "ml-2 md:ml-4 text-sm",
            (isCurrent ? "text-primaryColor font-bold" : "text-gray-500 font-medium cursor-default")
          )}
        >
          {step.label}
        </span>
      </span>
    </div>)
}

export default RegistrationStepsHeader;
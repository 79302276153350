import { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection, Pill } from "../components/Displays";
import _ from 'lodash';
import {
  Label,
  MultiSelectInput,
  SelectInput,
  TelephoneInput,
  TextInput,
} from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, isSystemAdmin, convertToLabelValueList } from "../utils/helpers";
import { ModalWrapper } from "../components/Modals";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function User() {
  const { currentUser, activeAccount } = useUserStore();
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [user, setUser] = useState(null);
  const [roleEditorOpen, setRoleEditorOpen] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState();
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    getAccountList();
    if (isCreatingNew) {
      setUser({
        id: 0,
        roles:[createNewRole()]
      });
      return;
    };
    setShowLoader(true);
    fetch(`user/getUserInfo/${id}`)
      .then((res) => {
        setUser(res);
        setSelectedCountry(res.countryCode);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve user", "error");
      })
      .finally(() => setShowLoader(false));
  }, []);

  const getAccountList = () => {
    if (!isSystemAdmin(currentUser)) {
      let tempAccountList = currentUser?.roles
      .filter(role => role.accountId)
      .map((role) => ({ label: role.accountName, value: role.accountId }));
      setAccountList(tempAccountList || []);
    }
    fetch(`admin/SimpleSystemAccountList`, {
      pageNumber: 1,
      pageSize: 1000,
    })
      .then((res) => {
        res = convertToLabelValueList(res);
        setAccountList(res);
      })
      .catch((err) => {
        alert("Server error", "Could not retrieve accounts", "error");
      })
      .finally(() => {});
  };

  const handleChange = (property, value) => {
    setUser({
      ...user,
      [property]: value,
    });
  };

  const handleSave = () => {
    let payloadUser = {...user};
    if (!isValid(payloadUser)) return;
    setShowLoader(true);
    post("user/saveUser", { ...payloadUser, countryCode: selectedCountry })
      .then(() => {
        alert("Success", "Saved user", "success");
        navigate("/users");
      })
      .catch((err) => {
        alert(
          "Unable to save user",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = (payloadUser) => {
    if (
      !payloadUser.firstName ||
      !payloadUser.firstName.trim() ||
      payloadUser.firstName.trim().length <= 1
    ) {
      alert("Invalid input", "First name is not valid", "warning");
      return false;
    }

    if (
      !payloadUser.lastName ||
      !payloadUser.lastName.trim() ||
      payloadUser.lastName.trim().length <= 1
    ) {
      alert("Invalid input", "Last name is not valid", "warning");
      return false;
    }

    if (!payloadUser.email || !payloadUser.email.trim() || !isValidEmail(payloadUser.email)) {
      alert("Invalid input", "Email is not valid", "warning");
      return false;
    }

    if (
      !payloadUser.username ||
      !payloadUser.username.trim() ||
      payloadUser.username.trim().length <= 1
    ) {
      alert("Invalid input", "Username is not valid", "warning");
      return false;
    }

    if (!payloadUser.mobilePhoneNumber || !payloadUser.mobilePhoneNumber.trim()) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    if (
      !isPossiblePhoneNumber(payloadUser.mobilePhoneNumber.trim(), selectedCountry)
    ) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }
    if (!payloadUser.roles || payloadUser.roles.length === 0) {
      alert("Invalid input", "User must have at least one role", "warning");
      return false;
    }
    return true;
  };

  function createNewRole() {
    return ({
      id: 0,
      userId: user.id, 
      accountId: isSystemAdmin(currentUser) ? null : currentUser.roles?.find(x => x.accountId).accountId,
      typeOfRole: isSystemAdmin(currentUser) ? null : constants.ROLE_IDS.ADMIN
    })
  }

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !user ? "Create new user" : `Manage ${user.username}`
        }
      />
      <GoBack display="Manage all users" navigateTo="/users" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="First Name"
                value={user?.firstName ?? ""}
                onChange={(e) => handleChange("firstName", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TextInput
                label="Last Name"
                value={user?.lastName ?? ""}
                onChange={(e) => handleChange("lastName", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={user?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                maxLength={50}
                required
              />
            </div>
            <div>
              <TextInput
                label="Username"
                value={user?.username ?? ""}
                onChange={(e) => handleChange("username", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TelephoneInput
                label="Phone Number"
                value={user?.mobilePhoneNumber ?? ""}
                onChange={(e) =>
                  handleChange("mobilePhoneNumber", e.target.value)
                }
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                required
              />
            </div>
          </div>
          {isSystemAdmin(currentUser)
              ? (<div className="p-4 flex flex-col gap-y-4">
                  <hr/>
                  <div className="flex flex-row flex-wrap justify-between my-4">
                    <span className="content-center"><h2>Roles</h2></span>
                    <span>
                      {isSystemAdmin(currentUser)
                        ? (<div className="hover:cursor-pointer" 
                            onClick={() => {
                              setSelectedUserRole(createNewRole())
                            }}
                          >
                            <Pill title={"Add role"}/>
                          </div>)
                        : null
                      }
                    </span>
                  </div>
                  {user?.roles && user.roles.length && user?.roles.map((r, idx) => (
                    <div key={`user-role-${idx}`} className="flex flex-row hover:cursor-pointer hover:text-slate-500" onClick={() => {setSelectedUserRole( r)}}>
                      <div className="flex flex-1">{constants.ROLES.find(x => x.value === r.typeOfRole)?.label}</div>  
                      <div className="flex flex-1">{r.accountName}</div>
                      <div>
                        <XMarkIcon
                          title="Remove"
                          className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            // console.log("user roles",user?.roles);
                            let newRoles = user?.roles?.filter(x => (x.accountId !== r.accountId));
                            // console.log("new roles",newRoles);
                            setUser({...user, roles: newRoles});
                            // toggleActiveStatus(x);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>)
              : null
            }
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
      <ModalWrapper open={!!selectedUserRole} setOpen={() => {setSelectedUserRole(undefined)}}>
        <div className="w-[85vw] lg:w-[35vw] h-[20vw] flex flex-col gap-y-4">
          <div>
            <SelectInput
              label={"Role"}
              options={isSystemAdmin(currentUser) ? constants.ROLES : constants.ROLES.filter(x => x.value !== constants.ROLE_IDS.SYS_ADMIN)}
              value={constants.ROLES.find(x => x.value === selectedUserRole?.typeOfRole)}
              onChange={(roleId) => {
                let temp = {
                  ...selectedUserRole, 
                  typeOfRole: roleId.value,
                  accountId: roleId.value === constants.ROLE_IDS.SYS_ADMIN ? null : selectedUserRole?.accountId,
                  accountName: roleId.value === constants.ROLE_IDS.SYS_ADMIN ? null : selectedUserRole?.accountName
                };
                setSelectedUserRole(temp);
              }}
              required
              isSearchable
            />
          </div>
          {selectedUserRole?.typeOfRole !== constants.ROLE_IDS.SYS_ADMIN &&
            <div>
              <SelectInput
                label={"Account"}
                options={accountList}
                value={accountList.find(x => x.value === selectedUserRole?.accountId)}
                onChange={(accountOption) => {
                  if (user?.roles.some(ur => ur.accountId === accountOption.value)) {
                    alert("Invalid account", "User already contains a role for this account", "warning");
                    return;
                  }
                  setSelectedUserRole({...selectedUserRole, accountId: accountOption.value, accountName: accountOption.label})
                }}
                required={!isSystemAdmin(currentUser)}
                onClear={() => {setSelectedUserRole({...selectedUserRole, accountId: undefined})}}
                isSearchable
              />
            </div>}
          <div>
            <PrimaryButton
              text={"Set Role"}
              onClick={()=> {
                let newRoles = [];
                if (selectedUserRole?.id === 0) {
                  newRoles = [...user.roles];
                }
                else{
                  newRoles = user.roles.filter(x => x.id !== selectedUserRole.id);
                }
                newRoles.push(selectedUserRole);
                setUser({...user, roles: newRoles});
                setSelectedUserRole(undefined);
              }}
            />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
}
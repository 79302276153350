import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Bank2, CreditCardFill, Icon } from "react-bootstrap-icons";
import { ModalWrapper } from ".";
import { useAlert, useApi } from "../../hooks";
import { classNames } from "../../utils/helpers";
import { PrimaryButton } from "../Buttons";
import { CheckboxInput } from "../Inputs";
import { useUserStore } from "../../context";

const PaymentMethodOption = ({
  isSelected,
  title,
  Icon,
  disabled,
  onClick,
}) => {
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={classNames(
        "mr-3 w-36 rounded-md bg-backgroundColor border p-4",
        isSelected ? "border-primaryColor" : "hover:bg-gray-200",
        disabled ? "cursor-not-allowed" : "cursor-pointer "
      )}
    >
      <Icon
        className={classNames(
          "h-5 w-5",
          isSelected ? "text-primaryColor" : "text-gray-700"
        )}
      />
      <div
        className={classNames(
          "mt-1.5 text-sm",
          isSelected ? "text-primaryColor" : "text-gray-700"
        )}
      >
        {title}
      </div>
      {disabled && (
        <div className="text-sm italic text-gray-500">Not available yet</div>
      )}
    </div>
  );
};

const CardInput = ({ label, children }) => {
  return (
    <div>
      <div className="mb-2">{label}</div>
      <div className="rounded-md bg-gray-100 p-2 border border-gray-200 shadow-sm outline-1 outline-fabricPink hover:bg-gray-200 focus:outline">
        {children}
      </div>
    </div>
  );
};

export default function PaymentMethodModal({
  modalOpen,
  existingPaymentMethodCount,
  refreshData,
  setModalOpen,
}) {
  const alert = useAlert();
  const { post } = useApi();
  const stripe = useStripe();
  const elements = useElements();
  const { activeSystemTenant, activeAccount } = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(true);
  const [paymentMethodType, setPaymentMethodType] = useState(
    "card"
  );
  
  const handleCardSubmit = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    if (!cardNumberElement || !cardCvcElement || !cardExpiryElement) return;

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (result.error) {
      alert(
        "Error",
        result.error.message ||
          "An error occurred while attaching the payment method",
        "ERROR"
      );
      setIsLoading(false);
      return;
    }

    post(
      `admin/AddPaymentMethodToSystemAccount/${activeAccount.id}`,{
        PaymentMethodId: result.paymentMethod.id,
        SetAsDefault: existingPaymentMethodCount === 0 ? true : defaultPaymentMethod // if no existing payment methods, auto select default (regardless of user input)
      }
    )
      .then(() => {
        alert("Success", "Successfully created payment method", "success");
        refreshData();
        setModalOpen(false);
      })
      .catch((err) => {
        alert(
          "Error",
          err.message || "An error occurred while attaching the payment method",
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[85vw] lg:w-[35vw]">
        {/* Payment type options */}
        <div className="mb-6 flex">
          <PaymentMethodOption
            isSelected={paymentMethodType === "card"}
            title="Card"
            Icon={CreditCardFill}
            onClick={() => setPaymentMethodType("card")}
          />
          <PaymentMethodOption
            isSelected={paymentMethodType === "ach"}
            title="US bank account"
            Icon={Bank2}
            onClick={() => setPaymentMethodType("ach")}
            disabled
          />
        </div>

        {paymentMethodType === "card" && (
          <>
            <div>
              <div className="grid gap-y-4">
                <CardInput label="Card number">
                  <CardNumberElement options={{ showIcon: true }} />
                </CardInput>
                <div className="grid grid-cols-4 gap-x-4">
                  <div className="col-span-3">
                    <CardInput label="Expiration date">
                      <CardExpiryElement />
                    </CardInput>
                  </div>
                  <CardInput label="Security code (CVC)">
                    <CardCvcElement />
                  </CardInput>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <CheckboxInput
                label="Set as default payment method"
                value={defaultPaymentMethod ? "checked" : "unchecked"}
                onChange={() => setDefaultPaymentMethod((x) => !x)}
              />
            </div>
            <div className="mt-8">
              <PrimaryButton
                text="Add payment method"
                onClick={handleCardSubmit}
                disabled={isLoading}
              />
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}

import constants from "../utils/constants";

let inMemoryStorage = {};

const isSupported = () => {
  try {
    localStorage.setItem("test", "test");
    localStorage.removeItem("test");
    return true;
  } catch (e) {
    return false;
  }
};

const clear = () => {
  if (isSupported()) {
    localStorage.clear();
  } else {
    inMemoryStorage = {};
  }
};

const getItem = (key) => {
  if (isSupported()) return localStorage.getItem(key);
  else if (inMemoryStorage.hasOwnProperty(key)) return inMemoryStorage[key];
  else return null;
};

const removeItem = (key) => {
  if (isSupported()) localStorage.removeItem(key);
  else delete inMemoryStorage[key];
};

const setItem = (key, value) => {
  if (isSupported()) localStorage.setItem(key, value);
  else inMemoryStorage[key] = value;
}

const ITEMS = {
  currentUser: "currentUser",
  jwt: `${constants.APP_NAME}-jwt`,
  storageCurrentUser: "storageCurrentUser",
  storageJWT: `storage-${constants.APP_NAME}-jwt`,
  username: `${constants.APP_NAME}-username`,
  systemTenants: "systemTenants",
  activeSystemTenant: "activeSystemTenant",
  activeAccount: "activeAccount",
}

const storage = {
  clear,
  getItem,
  removeItem,
  setItem,
  ITEMS,
}

export default storage;
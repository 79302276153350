import {
  ArrowDownCircleIcon, HomeIcon, CodeBracketIcon, UserGroupIcon, DocumentTextIcon, GiftIcon, KeyIcon, AdjustmentsHorizontalIcon, BanknotesIcon
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDevManager, useLayoutStore, useUserStore } from "../../context";
import { classNames, isDev } from "../../utils/helpers";
import _ from "lodash";
import { useApi } from "../../hooks";
import constants from "../../utils/constants";

let NAVIGATION_ITEMS = [
  { name: "Dashboard", icon: <HomeIcon />, href: "/", isHome: true },
  { name: "Users", icon: <UserGroupIcon />, href: "/users" },
  { name: "Products", icon: <GiftIcon />, href: "/products" },
  { name: "System Tenant Api Keys", icon: <KeyIcon />, href: "/systemTenantApiKeys" },
  { name: "System Tenants", icon: <AdjustmentsHorizontalIcon />, href: "/systemTenants" },
  { name: "Payments", icon: <BanknotesIcon />, href: "/payments" },
];

const NavItem = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSubItems, setShowSubItems] = useState(false);

  const [sidebarMenuOpen, setSidebarMenuOpen] = useLayoutStore((state) => [
    state.sidebarMenuOpen,
    state.setSidebarMenuOpen,
  ]);

  const isActive = item.isHome
    ? location.pathname === "/"
    : location.pathname.startsWith(item.href) && location.pathname !== "/";

  useEffect(() => {
    if (!sidebarMenuOpen) setShowSubItems(false);
  }, [sidebarMenuOpen]);

  return (
    <Fragment key={item.name + item.href}>
      <button
        onClick={() => {
          if (item.subItems) {
            setSidebarMenuOpen(true);
            setShowSubItems((show) => !show);
          } else navigate(item.href);
        }}
        key={item.name}
        className={classNames(
          isActive ? "bg-gradient-to-r from-primaryColor via-primaryColor/70 to-gray-100 to-25%" : "text-gray-600 hover:text-gray-700",
          "group flex items-center p-6 h-8 text-sm font-large relative w-full"
        )}
      >
        {/* Left decoration rectangle*/}
        {isActive && (
          <div className="bg-primaryColor p-1 absolute left-0 h-full" />
        )}
        {/* Icon */}
        <div
          title={item.name}
          className={classNames(
            isActive
              ? "text-gray-700"
              : "text-gray-400 group-hover:text-gray-500",
            "ml-2 mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        >
          {item.icon}
        </div>
        {/* Item name */}
        {sidebarMenuOpen && (
          <div className="mx-2 whitespace-nowrap">{item.name}</div>
        )}
      </button>

      {/* Submenu Items */}
      {showSubItems &&
        item.subItems &&
        item.subItems.map((subItem, i) => {
          return subItem.href ? <NavItemSubLink subItem={subItem} /> : <NavItemAccountButton subItem={subItem} index={i} /> 
        })}
    </Fragment>
  );
};

const NavItemSubLink = ({subItem, index}) => {
  const location = useLocation();
  const isActiveSubmenu = location.pathname.startsWith(subItem.href);

  return (
    <Link
      key={subItem.href + index}
      to={subItem.href}
      className={classNames(
        isActiveSubmenu ? "text-gray-900" : "",
        "justify-center flex text-sm text-gray-400 hover:text-gray-700 h-6 p-2 items-center"
      )}
    >
      {subItem.name}
    </Link>
  );
};

const NavItemAccountButton = ({subItem, index}) => {
  const { currentUser, activeSystemTenant } = useUserStore();
  const isActiveSubmenu = activeSystemTenant?.id === subItem.systemTenantId;
  return (
    <div
      key={subItem.name + index}
      onClick={subItem.onClick}
      className={classNames(
        isActiveSubmenu ? "text-gray-900" : "",
        "justify-center flex text-sm text-gray-400 hover:text-gray-700 h-6 p-2 items-center hover:cursor-pointer"
      )}
    >
      {subItem.name}
    </div>
  );
};

export default function SideNavMenu() {
  const [sidebarMenuOpen] = useLayoutStore((state) => [state.sidebarMenuOpen]);
  const { currentUser, systemTenants, setActiveSystemTenant, setActiveAccount } = useUserStore();
  const { getBinary } = useApi();
  const { IS_DEV } = useDevManager();
  useEffect(() => {
    if(systemTenants && systemTenants.length) {
      NAVIGATION_ITEMS = NAVIGATION_ITEMS.filter(item => item.name !== "Switch Account");
      NAVIGATION_ITEMS.push({
        name: "Switch Account",
        icon: <ArrowDownCircleIcon />,
        // href: "/",
        subItems: _.map(systemTenants, (st) => ({
          name: st.name,
          systemTenantId: st.id,
          onClick: () => {
            setActiveSystemTenant(st);
            setActiveAccount({
              id: st.accountId, 
              name: st.accountName
            }); 
          }
        }))
      });
    }
    else{
      NAVIGATION_ITEMS = NAVIGATION_ITEMS.filter(item => item.name !== "Switch Account");
    }
    if(IS_DEV && !NAVIGATION_ITEMS.some(x => x.name === "Developer")) {
      NAVIGATION_ITEMS.push({ name: "Developer", icon: <CodeBracketIcon />, href: "/demo" });
    }
  }, [systemTenants]);

  function downloadDocs() {
    getBinary("Reference/GetDocumentation")
    .then((response) => {
     const blob = new Blob([response], {
       type: constants.MIME_TYPES.PDF
     });
     const downloadUrl = URL.createObjectURL(blob);
    //  const a = document.createElement('a');
    //  a.href = downloadUrl;
    // //  a.download = `TransactionReport_${currentDate}.pdf`;
    //  document.body.appendChild(a);
    //  a.click();
    //  URL.revokeObjectURL(downloadUrl);
    window.open(downloadUrl, '_blank', 'fullscreen=yes')
    })
    .catch((err) => {
      alert("Server Error", "Could not download report", "error");
    })
   }
  
  return (
    <Fragment>
      <div
        className={classNames(
          sidebarMenuOpen ? "w-64" : "w-20",
          "fixed top-16 flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white float-left h-[calc(100vh-4rem)] z-5 transform duration-200"
        )}
      >
        <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <nav className="flex-1 space-y-3 bg-white" aria-label="Sidebar">
            {NAVIGATION_ITEMS.map((item, i) => (
              <NavItem key={item.href + i} item={item} />
            ))}
          </nav>
        </div>
        <hr />
        <div className="flex flex-grow-0 flex-col overflow-y-auto overflow-x-hidden my-auto">
          {/* <span>Download Documentation</span> */}
            <button
              onClick={downloadDocs}
              key={"download-docs-button"}
              className={classNames(
                "text-gray-600 hover:text-gray-700",
                "group flex items-center p-6 h-8 text-sm font-large relative w-full my-2"
              )}
            >
              {/* Icon */}
              <div
                title="Download Documentation"
                className={classNames(
                  "text-gray-400 group-hover:text-gray-500",
                  "ml-2 mr-3 flex-shrink-0 h-6 w-6"
                )}
                aria-hidden="true"
              >
                <DocumentTextIcon/>                
              </div>
              {/* Item name */}
              {sidebarMenuOpen && (
                <div className="mx-2 whitespace-nowrap">Download Documentation</div>
              )}
            </button>
        </div>
      </div>
    </Fragment>
  );
}

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { PaymentMethodModal } from "../../components/Modals";
import { useAlert, useApi } from "../../hooks";
import constants from "../../utils/constants";
import dateHelpers from "../../utils/dateHelpers";
import { JsonTryParse, classNames, formatCurrency, resolveSystemTenantId } from "../../utils/helpers";
import AddNewPaymentMethod from "./AddNewPaymentMethod";
import AmountDue from "./AmountDue";
import CurrentSubscriptionInfo from "./CurrentSubscriptionInfo";
import DefaultPaymentMethod from "./DefaultPaymentMethod";
import PaymentMethod from "./PaymentMethod";
import Products from "./Products";
import {useLoaderStore, useUserStore} from "../../context";

const { DURATION_TYPES } = constants;

export default function Account() {

  const alert = useAlert();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, activeSystemTenant, systemTenants, activeAccount } = useUserStore();
  const { fetch, post } = useApi();
  const [data, setData] = useState(undefined);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [existingPaymentMethods, setExistingPaymentMethods] = useState([]);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductsDetails] = useState([]);
  const [paymentSummaryData, setPaymentSummaryData] = useState(undefined);
  const [selectedInterval, setSelectedInterval] = useState(constants.INTERVAL_TYPES[0].value);
  const [defaultStripePaymentId, setDefaultStripePaymentId] = useState();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [isLoadingPaymentMethodId, setIsLoadingPaymentMethodId] = useState(false);
  const [isLoadingPaymentSummary, setIsLoadingPaymentSummary] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(undefined);
  const [selectedDurationType, setSelectedDurationType] = useState("Months");
  const systemTenantId = resolveSystemTenantId(currentUser, activeSystemTenant);
  const currentProductDetails = productDetails && productDetails.length
    ? productDetails.find(p => p.productId === data?.currentSubscription?.productId)
    : undefined;

  useEffect(() => {
    getData();
    getSystemTenantPaymentSummary();
  }, []);
  
  useEffect(() => {
    getSystemTenantPaymentSummary();
  }, [selectedInterval, activeSystemTenant]);

  const defaultPaymentMethod =
    useMemo(() => {
      if (
        existingPaymentMethods.length === 0 ||
        defaultStripePaymentId === undefined
      )
        return undefined;
      return existingPaymentMethods.find(
        (x) => x.id === defaultStripePaymentId
      );
    }, [existingPaymentMethods, defaultStripePaymentId]);

  const getData = () => {
    setIsLoadingInitialData(true);
    setIsLoadingPaymentMethods(true);
    setIsLoadingPaymentMethodId(true);
    fetch(`admin/AccountInfo/${activeAccount.id}`)
      .then((res) => {
        let cardList = res.paymentMethods?.length
          ? res.paymentMethods.map(x => JsonTryParse(x?.json))
          : [];
        setData(res);
        setExistingPaymentMethods(
          cardList
        );
        setDefaultStripePaymentId(res.stripeDefaultPaymentId);
        if (res.products && res.products.length) {
          let filteredProducts = res.products.filter(p => p.productId !== constants.MARKT_MONTHLY_MEMBERSHIP_ID);
          setProducts(filteredProducts);
        }
        setProductsDetails(res.productDetails)
        if (
          res.currentSubscription &&
          res.currentSubscription.durationType !== undefined
        ) {
          let durationType = DURATION_TYPES.find(d => d.value === res.currentSubscription.durationType)?.label;
          if(durationType) setSelectedDurationType(durationType);
          // switch (res.currentSubscription.durationType) {
          //   case DURATION_TYPES.BY_NAME.Months:
          //     setSelectedDurationType("Months");
          //     break;
          //   case DURATION_TYPES.BY_NAME.Years:
          //     setSelectedDurationType("Years");
          //     break;
          // }
        }
      })
      .catch(() => {
        alert(
          "Error while fetching account info",
          "Could not fetch account info",
          "error"
        );
      })
      .finally(() => {
        setIsLoadingInitialData(false);
        setIsLoadingPaymentMethodId(false);
        setIsLoadingPaymentMethods(false);
      });
  };

  const getSystemTenantPaymentSummary = () => {
    setIsLoadingPaymentSummary(true);
    let payload = {
      endDate: new Date(),
      timeInterval: selectedInterval
    };
    post(`admin/GetSystemTenantPaymentSummary/${activeSystemTenant?.id}`, payload)
    .then(res => {
      setPaymentSummaryData(res);
    })
    .catch(() => {
      alert(
        "Error while fetching payment summary",
        "Could not fetch payment summary info",
        "error"
      );
    })
    .finally(() => {
      setIsLoadingPaymentSummary(false);
    });
  }

  const getPaymentMethods = () => {
    setIsLoadingPaymentMethods(true);
    fetch(`admin/ListSystemAccountPaymentMethods/${activeAccount.id}`)
      .then((res) => {
        if(res && res.length){
          let cardList = res.map(x => JsonTryParse(x?.json));
          setExistingPaymentMethods(cardList);
        }
        
      })
      .finally(() => {
        setIsLoadingPaymentMethods(false);
      });
  };

  const getDefaultPaymentMethodId = () => {
    setIsLoadingPaymentMethodId(true);
    fetch(`admin/GetSystemAccountDefaultPaymentMethod/${activeAccount.id}`)
      .then((res) => {
        setDefaultStripePaymentId(res?.id);
      })
      .finally(() => {
        setIsLoadingPaymentMethodId(false);
      });
  };

  return (
    <div className="h-screen p-4 space-y-4">
      {paymentMethodModalOpen && (
        <PaymentMethodModal
          modalOpen={paymentMethodModalOpen}
          existingPaymentMethodCount={existingPaymentMethods?.length || 0}
          refreshData={() => {
            getPaymentMethods();
            getDefaultPaymentMethodId();
          }}
          setModalOpen={setPaymentMethodModalOpen}
        />
      )}
      {/* Header */}
      <div className="">
        <div className="font-semibold text-xl mb-1">Account</div>
        <div className="text-gray-500">
          Manage your subscriptions and account information
        </div>
      </div>

      {/* Amount due */}
      {typeof data?.currentSubscription?.renewalProductPriceInPennies === "number" &&
        data.currentSubscription.renewalProductPriceInPennies > 0 && (
          <AmountDue
            amountInPennies={
              data?.currentSubscription?.renewalProductPriceInPennies || 0
            }
            dueOn={data?.currentSubscription?.renewalEffectiveAt || ""}
          />
        )}

      {/* Current subscription and default payment method */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <CurrentSubscriptionInfo
          name={data?.currentSubscription?.name || ""}
          description={data?.currentSubscription?.description || ""}
          maxSystemTenants={currentProductDetails?.numberOfSystemTenants}
          systemTenantCount={systemTenants?.length || 0}
          message={
            data?.currentSubscription?.productId ===
            data?.currentSubscription?.renewalProductId
              ? `Autorenews on ${dateHelpers.toDateString(
                  data?.currentSubscription?.effectiveThrough || ""
                )}`
              : `You will be switched to ${
                  data?.currentSubscription?.renewalProductName
                } on ${dateHelpers.toDateString(
                  data?.currentSubscription?.renewalEffectiveAt || ""
                )}`
          }
          paymentSummaryData={paymentSummaryData}
          systemTenant={activeSystemTenant}
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
          isLoading={isLoadingInitialData}
        />

        <DefaultPaymentMethod
          defaultPaymentMethod={defaultPaymentMethod}
          existingPaymentMethodCount={existingPaymentMethods?.length || 0}
          isLoading={isLoadingPaymentMethodId || isLoadingPaymentMethodId}
          setPaymentMethodModalOpen={setPaymentMethodModalOpen}
        />
      </div>

      {/* Payment methods */}
      <div>
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-4">
          {isLoadingPaymentMethods
            ? _.times(3, (i) => (
                <Skeleton key={`skeleton-payment=${i}`} height="150px" />
              ))
            : existingPaymentMethods.map((x, i) => (
                <PaymentMethod
                  key={`payment-method-${i}`}
                  paymentMethod={x}
                  isDefault={x.id === defaultStripePaymentId}
                  refreshData={() => {
                    getPaymentMethods();
                    getDefaultPaymentMethodId();
                  }}
                  systemTenant={activeSystemTenant}
                />
              ))}
          {existingPaymentMethods.length >= 0 &&
            existingPaymentMethods.length < constants.MAX_PAYMENT_TYPES && (
              <AddNewPaymentMethod
                setPaymentMethodModalOpen={setPaymentMethodModalOpen}
              />
            )}
        </div>
        {existingPaymentMethods.length >= constants.MAX_PAYMENT_TYPES && (
          <div className="flex justify-end text-gray-500 italic">
            You have reached the maximum number of payment methods. Please
            remove a payment method to add a new one.
          </div>
        )}
      </div>

      {/* Products */}
      <Products
        products={products}
        productDetails={productDetails}
        currentSubscription={data?.currentSubscription}
        defaultPaymentMethod={defaultPaymentMethod}
        selectedDurationType={selectedDurationType}
        setSelectedDurationType={setSelectedDurationType}
        refreshData={getData}
      />

      {/* Payment history */}
      <div className="pt-2 pb-8">
        <div className="border-b border-gray-200 pb-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Payment History
          </h3>
        </div>
        <ul className="mt-4">
          {data?.payments.map((x, i) => {
            const isSuccess = x.succeededAt;
            const timeStamp = isSuccess ? x.succeededAt : x.failedAt;
            const message = isSuccess ? x.message : x.failureMessage;

            return (
              <li key={`payment-${x.ownedProductId}`}>
                <div className="relative pb-8">
                  {i !== data.payments.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-backgroundColor"
                        )}
                      >
                        {isSuccess ? (
                          <CheckCircleIcon className="w-8 h-8 text-success bg-backgroundColor" />
                        ) : (
                          <XCircleIcon className="w-8 h-8 text-error bg-backgroundColor" />
                        )}
                      </span>
                    </div>
                    <div className="pt-1.5">
                      <p>
                        {timeStamp && dateHelpers.toDateString(timeStamp)} &#40;
                        {/* {timeStamp && dateHelpers.localDateTime(timeStamp)} &#40; CANT USE LOCAL DATETIME BECAUSE MESSAGE CONTAINS UTC TIMES*/}
                        {formatCurrency(x.amount, true)} &#41;
                      </p>
                      <p className="text-sm text-gray-500">{message} </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

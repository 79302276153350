import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue, resolveSystemTenantId, formatCurrency, isDev } from "../utils/helpers";
import dateHelpers from "../utils/dateHelpers";

export default function Payment() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, activeSystemTenant } = useUserStore();

  const [payment, setPayment] = useState({ id });

  const [ownedProductIdData, setOwnedProductIdData] = useState([]);
  const IS_DEV = isDev();

  useEffect(() => {
    if (isCreatingNew) return;
    setShowLoader(true);
    fetch(`admin/paymentInfo/${id}/${resolveSystemTenantId(currentUser, activeSystemTenant)}`)
      .then((res) => {
        setPayment(res);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve payment", "error");
      })
      .finally(() => setShowLoader(false));
  }, []);

  // useEffect(() => {
  //   fetch("admin/getOwnedProductsSimple", { sortField: "Name" })
  //      .then((res) => {
  //          setOwnedProductIdData(res);
  //      });
  // }, [])
  // add swr calls here


  // TODO: get rid of swr call above for fk in sgb

  const handleChange = (property, value) => {
    setPayment({
      ...payment,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("admin/savePayment", payment)
      .then((res) => {
        alert("Success", "Saved payment", "success");
        navigate("/payments");
      })
      .catch((err) => {
        alert(
          "Unable to save payment",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!payment.amount) {
      alert("Form invalid", "Amount must have a value", "warning");
      return false;
    }
    if (!payment.amount) {
      alert("Form invalid", "Amount must have a value", "warning");
      return false;
    }
    if (!payment.paymentInstrumentId || !payment.paymentInstrumentId.trim()) {
      alert("Form invalid", "Payment Instrument Id must have a value", "warning");
      return false;
    }
    if (!payment.amount) {
      alert("Form invalid", "Amount must have a value", "warning");
      return false;
    }
    if (!payment.paymentInstrumentId || !payment.paymentInstrumentId.trim()) {
      alert("Form invalid", "Payment Instrument Id must have a value", "warning");
      return false;
    }
    if (!payment.message || !payment.message.trim()) {
      alert("Form invalid", "Message must have a value", "warning");
      return false;
    }
    // add validations here

    return true;
  };

  function Field(label, value) {
    return (
      <div className="flex flex-direction-row items-center gap-x-2">
        <h3 className="font-semibold">{`${label}: `}</h3>
        <h3 className="">{value}</h3>
      </div>);
  }

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !payment ? "Create new payment" : `Manage payment ${IS_DEV ? payment.id : ""}`
        }
      />
      <GoBack display="Manage all payments" navigateTo="/payments" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
           {Field("Product Name", `${payment.ownedProductName}`)}
           {Field("Amount", payment.amount ? formatCurrency(payment.amount / 100) : "")}
           {Field("Payment Instrument Id", payment.paymentInstrumentId)}
           {Field("Message", payment.message)}
           {Field("Started At", payment.createdAt ? `${dateHelpers.localDateTime(payment.createdAt, dateHelpers.YMDHMSA)}` : "" )}
           {Field("Succeeded At", payment.succeededAt ? `${dateHelpers.localDateTime(payment.succeededAt, dateHelpers.YMDHMSA)}` : "" )}
           {Field("Failed At", payment.failedAt ? `${dateHelpers.localDateTime(payment.failedAt, dateHelpers.YMDHMSA)}` : "" )}
           {Field("Failure Message Public", payment.failureMessagePublic)}
           {Field("Failure Message Internal", payment.failureMessageInternal)}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        {/* <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div> */}
      </div>
    </div>
  );
}

import {
    ArrowPathIcon,
    PlusCircleIcon,
    XMarkIcon,
    CheckIcon,
    PlusIcon,
  } from "@heroicons/react/24/solid";
  import { useState, useEffect } from "react";
  import { useNavigate } from "react-router-dom";
  import { TextInput, ToggleInput, CheckboxInput } from "../components/Inputs";
  import { PageHeader } from "../components/Layouts";
  import { ActiveFilter, PrimaryTable } from "../components/Tables";
  import { useDevManager, useLoaderStore, useUserStore } from "../context";
  import { useAlert, useApi } from "../hooks";
  import constants from "../utils/constants";
  import dateHelpers from "../utils/dateHelpers";
  import { formatPhoneNumber, resolveDurationCount, resolveSystemTenantId, switchSortDirection, userHasRole } from "../utils/helpers";
  import { PrimaryButton } from "../components/Buttons";
  import { Popover } from "@headlessui/react";
  
  const EMPTY_FILTERS = {
    activeOnly: false,
  };
  
  export default function SystemAccounts() {
    const navigate = useNavigate();
    const alert = useAlert();
    const { post, fetch } = useApi();
    const { setShowLoader } = useLoaderStore();
    const { currentUser, activeSystemTenant } = useUserStore();
    const { IS_DEV } = useDevManager();
    const [activeFilters, setActiveFilters] = useState({
      ...EMPTY_FILTERS,
      activeOnly: true,
    });
    const [stagedFilters, setStagedFilters] = useState(activeFilters);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDirection, setSortDirection] = useState(
      constants.SORT_DIRECTIONS.ASCENDING
    );
    const [sortField, setSortField] = useState("id");
    const [displayCount, setDisplayCount] = useState(
      constants.DISPLAY_PER_PAGE ?? 25
    );
    const [data, setData] = useState({ list: [], totalCount: 0 });
    const [isLoading, setIsLoading] = useState(false);
  
    const isSysAdmin = userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN);
  
    useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, displayCount, activeFilters, sortDirection, sortField]);
  
    useEffect(() => {
      setStagedFilters(activeFilters);
    }, [activeFilters]);
  
    const getData = () => {
      setIsLoading(true);
      fetch(`admin/SystemAccountList`, {
        pageNumber: currentPage,
        pageSize: displayCount,
        sortDirection,
        sortField,
        isExact: false,
        systemTenantId: resolveSystemTenantId(currentUser, activeSystemTenant),
        ...activeFilters,
      })
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          alert("Server error", "Could not retrieve accounts", "error");
        })
        .finally(() => setIsLoading(false));
    };
  
    const handleSort = (field) => {
      if (sortField === field) {
        setSortDirection(switchSortDirection(sortDirection));
      } else {
        setSortField(field);
      }
    };
  
  const toggleActiveStatus = (account) => {
    return;
    setShowLoader(true);
    post(`admin/NOT_IMPLEMENTED/${account.id}`)
    .then((res) => {
        alert("Success",
        res.message || "Account active status toggled",
        "success");
        getData();
    })
    .catch((err) => {
        alert("Error",
        err.data.message || "Account active status could not be toggled",
        "error");
        console.error(err);
    })
    .finally(() => {
        setShowLoader(false);
    });
  };
    // Insert toggleActiveStatus here
  
    return (
      <div>
        {/* Header */}
        <div className="flex justify-between">
          <PageHeader title="Manage System Accounts" />
          <PlusCircleIcon
            className="w-8 text-primaryColor hover:text-secondaryColor cursor-pointer"
            onClick={() => navigate("/systemAccounts/0")}
          />
        </div>
  
        {/* Filter section */}
        <AccountFilterSection
          stagedFilters={stagedFilters}
          setStagedFilters={setStagedFilters}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
  
        {/* Table */}
        <PrimaryTable
          isLoading={isLoading}
          totalCount={data?.totalCount}
          displayCount={displayCount}
          currentPage={currentPage}
          setDisplayCount={setDisplayCount}
          setCurrentPage={setCurrentPage}
          header={
            <PrimaryTable.Header>
              { IS_DEV &&
              <PrimaryTable.HeaderColumn
                display="Id"
                onSort={() => handleSort("id")}
                isSortingBy={sortField === "id"}
                sortDirection={sortDirection}
              />}
              <PrimaryTable.HeaderColumn
                display="Name"
                onSort={() => handleSort("name")}
                isSortingBy={sortField === "name"}
                sortDirection={sortDirection}
              />
              <PrimaryTable.HeaderColumn
                display="Email"
              />
              <PrimaryTable.HeaderColumn
                display="Phone"
                // onSort={() => handleSort("")}
                // isSortingBy={sortField === ""}
                // sortDirection={sortDirection}
              />
              <PrimaryTable.HeaderColumn display="" />
            </PrimaryTable.Header>
          }
        >
          {data &&
            data.list &&
            data.list.map((x, i) => (
              <PrimaryTable.Row
                key={`${x.id}-${i}`}
                index={i}
                onClick={(e) => {
                  if (!x.deactivatedAt) {
                    navigate(`/systemAccounts/${x.id}`);
                  }
                }}
              >
                {IS_DEV && <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>}
                <PrimaryTable.Cell>
                  {x.name}
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {x.email}
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {x.mobilePhone || ""}
                </PrimaryTable.Cell>
                {/* Insert cells here */}
                <PrimaryTable.Cell>
                  <div className="flex">
  {x.deactivatedAt ? (
      <ArrowPathIcon
          title="Activate"
          className="w-6 mx-1 text-white bg-green-500 rounded-md p-0.5 cursor-pointer"
          onClick={(e) => {
              e.stopPropagation();
              toggleActiveStatus(x);
          }}
      />
  ) : (
      <XMarkIcon
          title="Deactivate"
          className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
          onClick={(e) => {
              e.stopPropagation();
              toggleActiveStatus(x);
          }}
      />
  )}
                    {/* Insert flip active status here */}
                  </div>
                </PrimaryTable.Cell>
              </PrimaryTable.Row>
            ))}
        </PrimaryTable>
      </div>
    );
  }
  
  const AccountFilterSection = ({
    stagedFilters,
    setStagedFilters,
    activeFilters,
    setActiveFilters,
  }) => {
    return (
      <div className="relative mb-4 rounded-xs shadow-sm bg-white p-4">
        <Popover as="div" className="relative">
          {({ open }) => (
            <>
              <Popover.Button className="flex items-center text-sm bg-gray-100 px-3 py-2 rounded-sm hover:bg-gray-200 relative focus:outline-none">
                <PlusIcon className="w-4 mr-1" />
                FILTERS
              </Popover.Button>
              <Popover.Panel
                as="div"
                className="absolute grid gap-y-4 z-50 w-full sm:w-1/3 origin-bottom-right bg-white rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1"
              >
                <div>
                  <CheckboxInput
                    name="active-only"
                    label="Show only active users"
                    checked={stagedFilters.activeOnly === true}
                    onChange={() =>
                      setStagedFilters({
                        ...stagedFilters,
                        activeOnly: stagedFilters.activeOnly ? false : true,
                      })
                    }
                  />
                </div>
                <div>
                    <TextInput
                    label="Name"
                    placeholder="Enter Name" to filter by
                    value={stagedFilters.name}
                    onChange={(e) =>
                        setStagedFilters({
                        ...stagedFilters,
                        name: e.target.value || undefined,
                        })
                    }
                    />
                </div>
                {/* Add filters here */}
                <PrimaryButton
                  text="Apply filters"
                  onClick={() => setActiveFilters(stagedFilters)}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>
  
        {/* Selected filters */}
        {Object.values(activeFilters).find(
          (val) => val !== undefined && val !== false
        ) ? (
          <div className="flex items-center space-x-2 pt-6 text-sm">
            <div>Selected:</div>
            <ActiveFilter
              display="Show active only"
              value={
                activeFilters.activeOnly ? (
                  <CheckIcon className="w-4 text-green-600" />
                ) : undefined
              }
              onClear={() =>
                setActiveFilters({
                  ...activeFilters,
                  activeOnly: false,
                })
              }
            />
  
            <ActiveFilter
              display="Name"
              value={activeFilters.name}
              onClear={() =>
                setActiveFilters({
                  ...activeFilters,
                  name: undefined,
                })
              }
            />
            {/* Add active filters here */}
  
            <div
              className="cursor-pointer bg-gray-100 px-3 py-2 text-xs hover:bg-gray-200"
              onClick={() => {
                setActiveFilters({ ...EMPTY_FILTERS });
              }}
            >
              Clear all
            </div>
          </div>
        ) : (
          <div className="pt-6 text-sm text-gray-400">No active filters</div>
        )}
      </div>
    );
  };
  
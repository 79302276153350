import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
  CheckIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, ToggleInput, CheckboxInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { ActiveFilter, PrimaryTable } from "../components/Tables";
import { useDevManager, useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { formatPhoneNumber, resolveSystemTenantId, switchSortDirection } from "../utils/helpers";
import { PrimaryButton } from "../components/Buttons";
import { Popover } from "@headlessui/react";

const EMPTY_FILTERS = {
  activeOnly: false,
};

export default function Payments() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, activeSystemTenant } = useUserStore();
  const { IS_DEV } = useDevManager();
  const [activeFilters, setActiveFilters] = useState({
    ...EMPTY_FILTERS,
    activeOnly: true,
  });
  const [stagedFilters, setStagedFilters] = useState(activeFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const [data, setData] = useState({ list: [], totalCount: 0 });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, displayCount, activeFilters, sortDirection, sortField]);

  useEffect(() => {
    setStagedFilters(activeFilters);
  }, [activeFilters]);

  const getData = () => {
    setIsLoading(true);
    fetch("admin/paymentList", {
      pageNumber: currentPage,
      pageSize: displayCount,
      sortDirection,
      sortField,
      isExact: false,
      systemTenantId: resolveSystemTenantId(currentUser, activeSystemTenant),
      ...activeFilters,
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        alert("Server error", "Could not retrieve payments", "error");
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  // Insert toggleActiveStatus here

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Payments" />
        <PlusCircleIcon
          className="w-8 text-primaryColor hover:text-secondaryColor cursor-pointer"
          onClick={() => navigate("/payments/0")}
        />
      </div>
      <span className="w-10 text-pretty"></span>
      {/* Filter section */}
      <PaymentFilterSection
        stagedFilters={stagedFilters}
        setStagedFilters={setStagedFilters}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            {IS_DEV &&
            <PrimaryTable.HeaderColumn
              display="Id"
              onSort={() => handleSort("id")}
              isSortingBy={sortField === "id"}
              sortDirection={sortDirection}
            />}
            <PrimaryTable.HeaderColumn
              display="Owned Product"
              onSort={() => handleSort("ownedProduct")}
              isSortingBy={sortField === "ownedProduct"}
              sortDirection={sortDirection}
            />
            <PrimaryTable.HeaderColumn
              display="Amount"
              onSort={() => handleSort("amount")}
              isSortingBy={sortField === "amount"}
              sortDirection={sortDirection}
            />
            <PrimaryTable.HeaderColumn
              display="Message"
            />
            <PrimaryTable.HeaderColumn
              display="Succeeded At"
              onSort={() => handleSort("succeededAt")}
              isSortingBy={sortField === "succeededAt"}
              sortDirection={sortDirection}
            />
            <PrimaryTable.HeaderColumn
              display="Failed At"
              onSort={() => handleSort("failedAt")}
              isSortingBy={sortField === "failedAt"}
              sortDirection={sortDirection}
            />
            {/* Insert column headers here */}
            <PrimaryTable.HeaderColumn display="" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(e) => {
                if (!x.deactivatedAt) {
                  navigate(`/payments/${x.id}`);
                }
              }}
            >
              {IS_DEV && <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>}
              <PrimaryTable.Cell>
                {x.ownedProductName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.amount}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {/* <div className="text-pretty whitespace-normal"> */}
                  {x.message}
                {/* </div> */}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.formatDateToShortDate(x.succeededAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.formatDateToShortDate(x.failedAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                <div className="flex">
                  {/* Insert flip active status here */}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </div>
  );
}

const PaymentFilterSection = ({
  stagedFilters,
  setStagedFilters,
  activeFilters,
  setActiveFilters,
}) => {
  return (
    <div className="relative mb-4 rounded-xs shadow-sm bg-white p-4">
      <Popover as="div" className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="flex items-center text-sm bg-gray-100 px-3 py-2 rounded-sm hover:bg-gray-200 relative focus:outline-none">
              <PlusIcon className="w-4 mr-1" />
              FILTERS
            </Popover.Button>
            <Popover.Panel
              as="div"
              className="absolute grid gap-y-4 z-50 w-full sm:w-1/3 origin-bottom-right bg-white rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1"
            >
              <div>
                <CheckboxInput
                  name="succeded-only"
                  label="Show only successful payments"
                  checked={stagedFilters.succeededPaymentsOnly === true}
                  onChange={() =>
                    setStagedFilters({
                      ...stagedFilters,
                      succeededPaymentsOnly: stagedFilters.succeededPaymentsOnly ? false : true,
                      failedPaymentsOnly: !stagedFilters.succeededPaymentsOnly ? false : stagedFilters.fucceededPaymentsOnly,
                    })
                  }
                />
              </div>
              <div>
                <CheckboxInput
                  name="failed-only"
                  label="Show only failed payments"
                  checked={stagedFilters.failedPaymentsOnly === true}
                  onChange={() =>
                    setStagedFilters({
                      ...stagedFilters,
                      failedPaymentsOnly: stagedFilters.failedPaymentsOnly ? false : true,
                      succeededPaymentsOnly: !stagedFilters.failedPaymentsOnly ? false : stagedFilters.succeededPaymentsOnly,
                    })
                  }
                />
              </div>
              <div>
                <CheckboxInput
                  name="refund-only"
                  label="Show only refunded payments"
                  checked={stagedFilters.refundsOnly === true}
                  onChange={() =>
                    setStagedFilters({
                      ...stagedFilters,
                      refundsOnly: stagedFilters.refundsOnly ? false : true,
                    })
                  }
                />
              </div>
              {/* Add filters here */}
              <PrimaryButton
                text="Apply filters"
                onClick={() => setActiveFilters(stagedFilters)}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      {/* Selected filters */}
      {Object.values(activeFilters).find(
        (val) => val !== undefined && val !== false
      ) ? (
        <div className="flex items-center space-x-2 pt-6 text-sm">
          <div>Selected:</div>
          <ActiveFilter
            display="Show active only"
            value={
              activeFilters.activeOnly ? (
                <CheckIcon className="w-4 text-green-600" />
              ) : undefined
            }
            onClear={() =>
              setActiveFilters({
                ...activeFilters,
                activeOnly: false,
              })
            }
          />

          {/* Add active filters here */}

          <div
            className="cursor-pointer bg-gray-100 px-3 py-2 text-xs hover:bg-gray-200"
            onClick={() => {
              setActiveFilters({ ...EMPTY_FILTERS });
            }}
          >
            Clear all
          </div>
        </div>
      ) : (
        <div className="pt-6 text-sm text-gray-400">No active filters</div>
      )}
    </div>
  );
};

/* 
THIS IS A DEMO PAGE OF COMPONENTS IN THIS STARTER APP
KEEP FOR DEVELOPMENT USE - REMOVE FOR PRODUCTION
*/

import {
  ArrowPathIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InfoButton, PrimaryButton } from "../components/Buttons";
import {
  CheckboxInput,
  DateInput,
  FileUpload,
  MultiSelectInput,
  NumberInput,
  PasswordInput,
  RadioInput,
  RadioInputGroup,
  SelectInput,
  TelephoneInput,
  TextAreaInput,
  TextInput,
  TimeInput,
  ToggleInput,
} from "../components/Inputs";
import { ConfirmationModal } from "../components/Modals";
import { PrimaryTable } from "../components/Tables";
import { useNotificationStore } from "../context";
import { useAlert } from "../hooks";
import { DUMMY_PEOPLE, TEMP_USERS } from "../utils/demo-data";

// NEED TO KNOW FOR STARTER APP:
// tailwind... tree shaking, custom configs
// zustand...
// useswr
// heroicons & bootstrap icons
// headless ui
// pages vs components (separation)

export default function Demo() {
  const alert = useAlert();
  const navigate = useNavigate();

  const { open, setOpen } = useNotificationStore();

  const [dummyInput, setDummyInput] = useState("");
  const [radioValue, setRadioValue] = useState("female");
  const [toggleOn, setToggleOn] = useState(true);
  const [singleSelect, setSingleSelect] = useState();
  const [multiSelect, setMultiSelect] = useState([DUMMY_PEOPLE[1].value]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [phoneInput, setPhoneInput] = useState("3369703485");
  const [importantFiles, setImportantFiles] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("US");

  return (
    <>
      {/*
      GLOBAL NOTIFICATION SYSTEM
      - takes in title, body, type (success, warning, or error), and whether to autoclose or not
      - uses Zustand for state management (https://docs.pmnd.rs/zustand/getting-started/introduction)
      */}
      <div className="my-4 p-4 bg-gray-200 rounded-lg">
        <h3 className="font-bold text-xl mb-3">Global Navigation System</h3>
        <div
          className="text-green-700 cursor-pointer"
          onClick={() =>
            alert("A success message", "Body of success message", "success")
          }
        >
          Click me to see a success message that autocloses
        </div>

        <div
          className="text-error cursor-pointer" // note: this (text-error) is a custom tailwind class (see in tailwind.config.js)
          onClick={() =>
            alert("An error message", "Body of error message", "error", false)
          }
        >
          Click me to see an error message that does not auto close
        </div>

        <div
          className="cursor-pointer"
          onClick={() =>
            alert(
              "A warning message",
              "Body of warning message",
              "warning",
              true
            )
          }
        >
          Click me to see a warning message that autocloses
        </div>

        <div
          className="mt-4 text-blue-700 hover:text-blue-500 cursor-pointer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Click me to open notification (example of Zustand)
        </div>
      </div>

      <div className="my-4 p-4 bg-gray-200 rounded-lg">
        <h3 className="font-bold text-xl mb-3">404 Page</h3>
        {/* Why use Link instead of anchor tag? Link allows you to navigate between different routes without causing a full page reload -- will lose context */}
        <Link
          to="/kjaslfkaskjerkfj"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-700 hover:text-blue-500"
        >
          Click me to navigate to a 404 page
        </Link>
      </div>

      <div className="my-4 p-4 bg-gray-200 rounded-lg">
        <h3 className="font-bold text-xl mb-3">Buttons</h3>

        <h6 className="font-semibold my-3">Primary button:</h6>
        <PrimaryButton text="Submit" onClick={() => {}} />

        <h6 className="font-semibold my-3">
          Primary button w/ styling changes:
        </h6>

        {/* <b>
          MAJOR NOTE ON TAILWIND: if color or some styling is NOT working, and
          it seems to be happening when you are passing in styling as a prop,
          the problem is tree shaking!!! If the color or style is not being used
          elsewhere in the code and the only time you're using it is as a
          prop/dynamic attribute, then it's not in the tailwind compilation; You
          will need to add the style to a 'safelist' in tailwind config or add
          the style somewhere in the code (and hide that div --- that's a hack
          though))
        </b> */}
        <div className="w-[7rem] my-4">
          <PrimaryButton
            text="Deploy"
            backgroundColor="pink-500"
            onClick={() => {}}
          />
        </div>

        <h6 className="font-semibold my-3">Info button:</h6>
        <div className="relative">
          <InfoButton>
            <div className="px-4">
              <div className="border-b mb-2">I am info to the user</div>
              Anything can go in here
            </div>
          </InfoButton>
        </div>
      </div>

      <div className="my-4 p-4 bg-gray-200 rounded-lg">
        <h3 className="font-bold text-xl mb-3">Modal</h3>

        <b>
          This is an example modal. All modals should most likely be wrapped in
          "ModalWrapper" Component
        </b>
        <div
          onClick={() => setConfirmationModalOpen(true)}
          className="text-blue-700 hover:text-blue-500 cursor-pointer"
        >
          Click me to open "ConfirmationModal"
        </div>

        <ConfirmationModal
          modalOpen={confirmationModalOpen}
          setModalOpen={setConfirmationModalOpen}
          title="Drop all tables?"
          message="Type your username in to confirm this"
          textToMatch="haliechm"
          confirmButtonText="Drop all tables"
        />
      </div>

      {/*
    INPUTS
      */}

      <div className="my-4 p-4 bg-gray-200 rounded-lg">
        <h3 className="font-bold text-xl mb-3">Inputs</h3>
        <h6 className="font-semibold my-3">Simple text input:</h6>
        <TextInput
          label="A label"
          subLabel="A sublabel"
          placeholder="a placeholder"
          required
          value={dummyInput}
          onChange={(e) => setDummyInput(e.target.value)}
          onClear={() => setDummyInput("")}
        />

        <i>
          Notice that input takes full width. To change sizing, wrap a div
          around:
        </i>

        <div className="w-1/2">
          <TextInput
            label="A label (text input has icon)"
            value={dummyInput}
            onChange={(e) => setDummyInput(e.target.value)}
            Icon={HomeIcon}
          />
        </div>

        <h6 className="font-semibold my-3">Password input:</h6>
        <PasswordInput
          label="Password"
          value={dummyInput}
          onChange={(e) => setDummyInput(e.target.value)}
          maxLength={5}
          placeholder="Placeholders are not required"
          showInfo
        />

        <h6 className="font-semibold my-3">Number input:</h6>
        <NumberInput
          value={dummyInput}
          onChange={(e) => setDummyInput(e.target.valueAsNumber)}
          max={10}
          min={1}
        />

        <h6 className="font-semibold my-3">Text area input:</h6>
        <TextAreaInput
          label="Text area input label"
          subLabel="A sublabel for text area input"
          placeholder="Placeholder here"
          required
          value={dummyInput}
          onChange={(e) => setDummyInput(e.target.value)}
          maxLength={15}
        />

        {/* NumberInput is pretty much same thing as TextInput but type is number
          and includes max/min */}

        <h6 className="font-semibold my-3">Individual radio input:</h6>
        <RadioInput
          id="randomId"
          label="Label of radio button"
          subLabel="Sublabel of radio button"
          value="coco"
        />

        <h6 className="font-semibold my-3">Group of radio inputs:</h6>
        <RadioInputGroup onChange={setRadioValue}>
          <RadioInput
            label="Female"
            value="female"
            groupName="gender"
            checked={radioValue === "female"}
          />
          <RadioInput
            label="Male"
            value="male"
            groupName="gender"
            checked={radioValue === "male"}
          />
          <RadioInput
            label="Other"
            value="other"
            groupName="gender"
            checked={radioValue === "other"}
          />
        </RadioInputGroup>

        <h6 className="font-semibold my-3">Toggle input:</h6>
        <ToggleInput
          label="Likes peanut butter"
          enabled={toggleOn}
          setEnabled={setToggleOn}
        />

        <h6 className="font-semibold my-3">Single select input:</h6>
        <SelectInput
          options={DUMMY_PEOPLE}
          label="Person"
          onClear={() => setSingleSelect({})}
          value={singleSelect}
          onChange={(value) => setSingleSelect(value)}
          placeholder="a placeholder"
          isSearchable
        />

        <h6 className="font-semibold my-3">Multi select input:</h6>
        <MultiSelectInput
          options={DUMMY_PEOPLE}
          placeholder="placeholders are not required"
          label="People"
          onClear={() => setMultiSelect([])}
          values={multiSelect}
          onChange={(value) => setMultiSelect(value)}
          isSearchable
        />

        <h6 className="font-semibold my-3">Checkbox input:</h6>
        <CheckboxInput
          label="I'm a checkbox"
          subLabel="short and stout"
          checked={toggleOn}
          onChange={() => setToggleOn(x => !x)}
        />

        <h6 className="font-semibold my-3">Date input:</h6>
        <DateInput
          label="Birth date"
          subLabel="Select your birthday"
          name="birth-date"
          required
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />

        <h6 className="font-semibold my-3">Time input:</h6>
        <TimeInput
          label="Favorite time"
          name="birth-date"
          required
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />

        <h6 className="font-semibold my-3">Phone input:</h6>
        <TelephoneInput
          name="phone"
          label="Phone number"
          value={phoneInput}
          onChange={(e) => setPhoneInput(e.target.value)}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          required
        />

        <h6 className="font-semibold my-3">File upload:</h6>
        <FileUpload
          files={importantFiles}
          setFiles={setImportantFiles}
          allowMultipleFiles={true}
          maxFileCount={2}
        />
      </div>

      <div className="my-4 p-4 bg-gray-200 rounded-lg">
        <h3 className="font-bold text-xl mb-3">Tables and Pagination</h3>

        <PrimaryTable
          header={
            <PrimaryTable.Header>
              <PrimaryTable.HeaderColumn display="First Name" sortable />
              <PrimaryTable.HeaderColumn display="Last Name" />
              <PrimaryTable.HeaderColumn display="Email" sortable />
              <PrimaryTable.HeaderColumn display="Username" sortable />
              <PrimaryTable.HeaderColumn display="Last Logged On" />
              <PrimaryTable.HeaderColumn display="" />
            </PrimaryTable.Header>
          }
        >
          {TEMP_USERS.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(event) => {
                if (!event.target.classList.contains("menu-button")) {
                  navigate(`/users/${x.id}`);
                }
              }}
            >
              <PrimaryTable.Cell>{x.firstName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.lastName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.email}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.username}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.lastLoggedOn}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                <div className="flex">
                  {x.isActive ? (
                    <XMarkIcon
                      title="Deactivate"
                      className="w-5 mx-1 text-delete-100 cursor-pointer hover:text-delete"
                    />
                  ) : (
                    <ArrowPathIcon
                      title="Activate"
                      className="w-5 mx-1 text-gray-700 cursor-pointer hover:text-400"
                    />
                  )}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
        </PrimaryTable>
      </div>
    </>
  );
}

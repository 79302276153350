import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

export default function CollapsibleSection({
  title,
  startOpen = true,
  children,
}) {
  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <div className="pb-2">
      <h4
        className="flex justify-between px-4 py-2 text-white font-semibold bg-primaryColor cursor-pointer"
        onClick={() => setIsOpen((x) => !x)}
      >
        {title}
        {isOpen ? (
          <ChevronDownIcon className="w-5 text-white cursor-pointer" />
        ) : (
          <ChevronUpIcon className="w-5 text-white cursor-pointer" />
        )}
      </h4>
      {isOpen && children}
    </div>
  );
}

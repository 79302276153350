import {
    ArrowPathIcon,
    PlusCircleIcon,
    XMarkIcon,
    CheckIcon,
    PlusIcon,
  } from "@heroicons/react/24/solid";
  import { useState, useEffect } from "react";
  import { useNavigate } from "react-router-dom";
  import { TextInput, ToggleInput, CheckboxInput, DateInput, SelectInput, SystemTenantTypeahead, Label } from "../components/Inputs";
  import { PageHeader } from "../components/Layouts";
  import { ActiveFilter, PrimaryTable, FilterSection } from "../components/Tables";
  import { useLoaderStore, useUserStore } from "../context";
  import { useAlert, useApi } from "../hooks";
  import constants from "../utils/constants";
  import dateHelpers from "../utils/dateHelpers";
  import { formatCurrency } from "../utils/helpers";
  import { formatPhoneNumber, resolveSystemTenantId, switchSortDirection, userHasRole } from "../utils/helpers";
  import { PrimaryButton } from "../components/Buttons";
  import { Popover } from "@headlessui/react";
  import _ from "lodash";
  import moment from "moment";
  
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstOfMonth = dateHelpers.getStringFromMoment(moment(new Date(year, month, 1)));
  const currentDate = dateHelpers.getStringFromMoment(moment(date));

  export default function TransactionReporting() {
    
    const alert = useAlert();
    const { post, fetch, postBinary } = useApi();
    const { setShowLoader } = useLoaderStore();
    const { currentUser, activeSystemTenant } = useUserStore();   
    const [systemTenant, setSystemTenant] = useState();
    const [isLoading, setIsLoading] = useState(false);    
    const [dateFrom, setDateFrom] = useState(firstOfMonth);
    const [dateThrough, setDateThrough] = useState(currentDate);
    const [transactionData, setTransactionData] = useState([]);    

    useEffect(() => {
      
    }, []);    

    function runReport() {
      setIsLoading(true);
      post("Admin/GetTransactionDetails", {
        dateFrom: dateFrom,
        dateThrough: dateThrough,
        systemTenantIdList: systemTenant?.value ? [systemTenant?.value] : null
      })
      .then((response) => {        
        setTransactionData(response);
        if (!response.length) {
          alert("No Results", "No results found", "warning");
        }
      })
      .catch((error) => {
        alert("Server Error", "Could not retrieve transaction Details", "error");
      })
      .finally(() => setIsLoading(false));
    }

    function downloadReport() {
     postBinary("Admin/GenerateTransactionDetailsReport", {
       dateFrom: dateFrom,
       dateThrough: dateThrough,
       systemTenantIdList: systemTenant?.value ? [systemTenant?.value] : null
     })
     .then((response) => {
      const blob = new Blob([response], {
        type: constants.MIME_TYPES.XLSX
      });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `TransactionReport_${currentDate}.xlsx`;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(downloadUrl);
     })
     .catch((err) => {
       alert("Server Error", "Could not download report", "error");
     })
    }

    function handleReset() {
      setDateFrom(null);
      setDateThrough(null);
      setSystemTenant(null)
    }


    return (
      <div className="w-100 h-auto">
        {/* Header */}
        <div className="justify-between grid grid-cols-10 gap-4 w-full p-4">
          
          <div className="col-span-3 min-2-full">
            <PageHeader title="Transaction Reporting" />    
          </div>
          <div className="col-span-1 min-2-full col-start-9 content-center">
            <PrimaryButton 
              onClick={runReport}
              text="Run Report"
              className="mr-2"
            />
          </div>
          <div className="col-span-1 min-2-full col-start-10 content-center">
            <PrimaryButton 
              onClick={downloadReport}
              text="Download Report"                
            /> 
          </div>                    
        </div>


        {/* Filter section */}
        <div className="bg-gray rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="w-full h-1/4 justify-center">          
            <ReportingFilterSection              
              currentUser={currentUser}
              systemTenant={systemTenant}
              setSystemTenant={setSystemTenant}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateThrough={dateThrough}
              setDateThrough={setDateThrough}
              handleReset={handleReset}
            />
          </div>
        </div>

        {/* Table Section */}
        <div>
          <ReportingTable 
          data={transactionData}
          isLoading={isLoading}
          />
        </div>

      </div>
    );
  }

const ReportingFilterSection = ({           
    currentUser,
    systemTenant, 
    setSystemTenant,
    dateFrom,
    setDateFrom,
    dateThrough,
    setDateThrough,
    handleReset
}) => {
  return (
    <div className="grid grid-cols-12 gap-4 w-full p-4">
      <div className="col-span-2 min-2-full">
      <DateInput 
        label="Date From"
        value={dateFrom}
        onChange={(e) => setDateFrom(e.target.value)}
      />
      </div>     
      <div className="col-span-2 min-2-full">
      <DateInput
        label="Date Through"
        value={dateThrough}
        onChange={(e) => setDateThrough(e.target.value)}
      />
      </div>
      <div className="col-span-2 min-2-full flex flex-col content-stretch">
        <Label label={"System Tenant"} />
        <SystemTenantTypeahead
          value={systemTenant}
          onChange={(e) => setSystemTenant(e)}
          limitToAccount={!userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN)}
          debounceTimeout={300}          
        />
      </div>
      <div className="col-span-1 col-start-11 col-end-12 self-center">
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="mt-3 p-2 w-1"        
          onClick={() => handleReset()}
        />
      </div>
    </div>
  );
}

const ReportingTable = ({data, isLoading}) => {
  return (
    <PrimaryTable    
    isLoading={isLoading}
    header={
      <PrimaryTable.Header>
        <PrimaryTable.HeaderColumn 
        display="Date Occured"
        />
        <PrimaryTable.HeaderColumn 
        display="Account Name"
        />
        <PrimaryTable.HeaderColumn 
        display="System Tenant Name"
        />
        <PrimaryTable.HeaderColumn 
        display="Message"
        />
        <PrimaryTable.HeaderColumn 
        display="Amount"
        />
        <PrimaryTable.HeaderColumn 
        display="Margot Okapi Fee"
        />
      </PrimaryTable.Header>
    }
    >
      {data ? (
        _.map(data, (x, i) => (
          _.map(x?.systemTenantData, (y, ind) => (
            _.map(y?.transactions, (z, ix) => (
              <PrimaryTable.Row
            key={`${z.id}-${ix}`}
            index={ix}
            >
              <PrimaryTable.Cell>
                {dateHelpers.formatDateToShortDate(z.createdAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {z.accountName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {z.systemTenantName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {z.message}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {formatCurrency(z.amount, true)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {formatCurrency(z.margotLicenseFeeAmount, true)}
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
            ))
          ))
        ))
      ) : null}      
    </PrimaryTable>
  );
}
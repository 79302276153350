import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { Label } from "../components/Inputs";
import { resolveSystemTenantId } from "../utils/helpers";
import dateHelpers from "../utils/dateHelpers";

export default function Activity() {
    let { id, systemTenantId } = useParams();
    const navigate = useNavigate();
    const alert = useAlert();
    const { setShowLoader } = useLoaderStore();
    const { currentUser, activeSystemTenant } = useUserStore();
    const { fetch, post } = useApi();
    const { PROFILE, ACTIVITY, NOTIFICATIONS } = constants.SYS_TENANT_TABS;
    const [activity, setActivity] = useState();
    useEffect(()=>{
        getActivity();
    }, []);

    function getActivity(){
        setShowLoader(true);
        fetch(`admin/apiOperationInfo/${id}/${systemTenantId}`)
        .then((res) => {
            setActivity(res);
        })
        .catch(() => {
            alert("Server error", "Could not retrieve product", "error");
        })
        .finally(() => setShowLoader(false));
    }

    return (
        <div>
            <GoBack display="Return to activites" navigateTo={`/systemTenants/${systemTenantId}?tab=${ACTIVITY}`} />
            <div className="my-4 p-4 bg-gray-200 rounded-lg">
                <h3 className="font-bold text-xl mb-3">Activity</h3>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                        <h6 className="font-semibold my-3">Id:</h6>
                        <h6 className="block text-sm font-normal text-gray-700">{activity?.id} </h6>
                    </div>
                </div>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                        <h6 className="font-semibold my-3">Operation Uri:</h6>
                        <h6 className="block text-sm font-normal text-gray-700">{activity?.operationUri} </h6>
                    </div>
                </div>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                        <h6 className="font-semibold my-3">Initiated At:</h6>
                        <h6 className="block text-sm font-normal text-gray-700">{dateHelpers.localDateTime(activity?.initiatedAt, dateHelpers.YMDHMSA)} </h6>
                    </div>
                </div>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                        <h6 className="font-semibold my-3">Completed At:</h6>
                        <h6 className="block text-sm font-normal text-gray-700">{dateHelpers.localDateTime(activity?.completedAt, dateHelpers.YMDHMSA)} </h6>
                    </div>
                </div>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                        <h6 className="font-semibold my-3">Duration (in miliseconds):</h6>
                        <h6 className="block text-sm font-normal text-gray-700">{activity?.durationMs} </h6>
                    </div>
                </div>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                        <h6 className="font-semibold my-3">Failure Message:</h6>
                        <h6 className="block text-sm font-normal text-gray-700">{activity?.failureMessage} </h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
import _ from "lodash";
import { Children } from "react";
import { Filter, SortDownAlt, SortUpAlt } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";
import { Paginator } from "../Pagination";

function PrimaryTable({
  header,
  children,
  isLoading,
  totalCount,
  displayCount,
  currentPage,
  setCurrentPage,
  setDisplayCount,
}) {
  const columnCount = Children.count(header.props.children);

  return (
    <>
      <div className="relative overflow-x-auto shadow-sm border-none">
        <table className="min-w-full border-none table-fixed">
          {header}
          <tbody>
            <tr></tr>
            {isLoading ? (
              _.times(5, (j) => (
                <PrimaryTable.Row key={`row-${j}`}>
                  {_.times(columnCount, (i) => (
                    <PrimaryTable.Cell key={i}>
                      <Skeleton height="25px" />
                    </PrimaryTable.Cell>
                  ))}
                </PrimaryTable.Row>
              ))
            ) : (
              <>
                {children}
                <PrimaryTable.Row>
                  <td colSpan={columnCount}>
                    <Paginator
                      count={totalCount}
                      display={displayCount}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      setDisplay={setDisplayCount}
                    />
                  </td>
                </PrimaryTable.Row>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

function Row({ children, index, onClick }) {
  return (
    <tr
      key={`row-${index}`}
      className={classNames(
        "hover:bg-gray-100 cursor-pointer",
        index % 2 === 0 ? "" : "bg-gray-50"
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  );
}

function Cell({ children }) {
  return (
    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-tempest border border-gray-100">
      {children}
    </td>
  );
}

function Header({ children }) {
  return (
    <thead className="bg-white">
      <tr className="">{children}</tr>
    </thead>
  );
}

function HeaderColumn({ display, isSortingBy, onSort, sortDirection }) {
  return (
    <th
      scope="col"
      className="px-6 py-4 text-left text-sm font-medium text-gray-700 uppercase tracking-wider w-72 border"
    >
      <div className="flex items-center">
        <div className="flex items-center">
          <div className="mr-1 cursor-pointer text-primaryColor hover:text-gray-400">
            {onSort &&
              (isSortingBy ? (
                sortDirection === constants.SORT_DIRECTIONS.ASCENDING ? (
                  <SortDownAlt
                    title="Sort ascending"
                    size={18}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSort();
                    }}
                  />
                ) : (
                  <SortUpAlt
                    title="Sort descending"
                    size={18}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSort();
                    }}
                  />
                )
              ) : (
                <Filter
                  title={`Sort by ${display}`}
                  size={18}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSort();
                  }}
                />
              ))}
          </div>
          {display}
        </div>
      </div>
    </th>
  );
}

PrimaryTable.Row = Row;
PrimaryTable.Cell = Cell;
PrimaryTable.Header = Header;
PrimaryTable.HeaderColumn = HeaderColumn;

export default PrimaryTable;

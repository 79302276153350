import React from "react";
export default function TabView({tabs = [], setActiveTab, activeTab}){
    function createTab(tab, idx){
        // const Icon = tab.icon.render;
        return (
            <button 
                key={`tab-${idx}`} 
                type="button" 
                class={`text-gray-500 py-4 px-4 inline-flex items-center border-b-2 text-sm whitespace-nowrap hover:text-blue-600 content-center items-center min-w-20 ${activeTab === tab.name ? "font-semibold border-primaryColor text-primaryColor" : ""}`} 
                id="tabs-with-icons-item-1" 
                data-hs-tab="#tabs-with-icons-1" 
                aria-controls="tabs-with-icons-1" 
                role="tab" 
                onClick={() => {setActiveTab(tab.name)}}
            >
                <span class="flex flex-row justify-center text-center">
                <span className="self-center mr-2">{tab.name}</span>
                {tab.icon && (<tab.icon class="h-8" />)}
                </span>
            </button>
        );
    }
    return(
        <div class="border-b border-b-gray-500 dark:border-b-gray-700">
            {tabs.map((tab, idx) => createTab(tab, idx))}
        </div>
    )
}
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton, TabButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput, Label, TelephoneInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useDevManager, useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue, resolveSystemTenantId, userHasRole, resolveDurationCount, resolveOwnedProductDurationCount, formatCurrency, classNames } from "../utils/helpers";
import { isValidPhoneNumber } from "react-phone-number-input";
import dateHelpers from "../utils/dateHelpers";
import { CancelConfirmationModal, ConfirmationModal, RefundConfirmationModal } from "../components/Modals";

export default function AccountDetail() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const { fetch, post, del } = useApi();
  const { setShowLoader } = useLoaderStore();
  const {IS_DEV} = useDevManager();
  const { currentUser, activeSystemTenant } = useUserStore();
  const [account, setAccount] = useState({ id });
  const [accountUsers, setAccountUsers] = useState([]);
  const [accountSubscription, setAccountSubscription] = useState();
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [showCancel, setShowCancel] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [downgradeToFree, setDowngradeToFree] = useState(false);
  const isSysAdmin = userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN);
  const hasRenewal = !!accountSubscription?.renewalProductName;
  const canRefund = accountSubscription?.price > 0;

  useEffect(() => {
    // getUserOptions();
    getAccount();
    if (isSysAdmin) {
      getAccountSubscription();
    }
  }, []);

  function getAccountSubscription(){
    setShowLoader(true);
    fetch(`admin/AccountCurrentSubscription/${id}`)
    .then((res) => {
      setAccountSubscription(res);
    })
    .catch(() => {
      alert("Server error", "Could not retrieve account subscription", "error");
      setAccountSubscription(undefined);
    })
    .finally(() => setShowLoader(false));
  }

  function getAccount(){
    setShowLoader(true);
    fetch(`admin/SystemAccountInfo/${id}`)
    .then((res) => {
      setAccount(res);
    })
    .catch(() => {
      alert("Server error", "Could not retrieve account information", "error");
    })
    .finally(() => setShowLoader(false));
  }

  function getUserOptions() {
    fetch("user/GetAccountUserOptions", { sortField: "Name" })
    .then((res) => {
        let transformedList = convertToLabelValueList(res);
        setAccountUsers(transformedList);
    });
  }

  const handleChange = (property, value) => {
    setAccount({
      ...account,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;
    setShowLoader(true);
    post("admin/SaveAccount", account)
      .then((res) => {
        alert("Success", "Saved account", "success");
        navigate("/");
      })
      .catch((err) => {
        alert(
          "Unable to save account",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!account.name || !account.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!account.email || !account.email.trim() || !isValidEmail(account.email)) {
      alert("Form invalid", "Not a valid email address", "warning");
      return false;
    }
    if (!account.mobilePhone || !account.mobilePhone.trim()) {
      alert("Form invalid", "Phone nuumber must have a value", "warning");
      return false;
    }

    return true;
  };

  function CancelSubscription() {
    setShowLoader(true);
    del(`admin/CancelSystemAccountSubscription/${accountSubscription?.ownedProductId}/${downgradeToFree}`)
    .then(res => {
      alert("Subscription Cancelled", "", "success");
      getAccountSubscription();
    })
    .catch(err => {
      alert("Server error", err?.Message || "Error cancelling subscription", "error");
    })
    .finally(() => {
      setShowCancel(false);
      setShowLoader(false);
    });
  }

  function RefundSubscription(refundAmount, refundReason) {
    if(!accountSubscription) return;
    setShowLoader(true);
    let payload = {
      OwnedProductId: accountSubscription.ownedProductId,
      PartialRefundAmount: refundAmount,
      PartialRefund: refundAmount < accountSubscription.price,
      RefundReason: refundReason || null,
      NotifyTenantUsers: true
    };
    del(`admin/RefundSystemAccountSubscription/${id}`, payload)
    .then(res => {
      alert("Subscription Refunded", "", "success");
      getAccountSubscription();
    })
    .catch(err => {
      alert("Server error", err?.Message || "Error refunding subscription", "error");
    })
    .finally(()=>{
      setShowRefund(false);
      setShowLoader(false);
    });
  }
  
  return (
    <div>
      {/* Header */}
      <PageHeader
        title={`Manage account ${account.name}`}
      />
      <GoBack display="Return to dashboard" navigateTo="/" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div className="flex flex-direction-row col-span-3 items-center gap-x-2">
                <h3 className="font-semibold">{"Stripe Customer Id:"}</h3>
                <h3 className="">{`${account.stripeCustomerId}`}</h3>
            </div>
            <div>
              <TextInput
                label="Name"
                value={account?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={250}
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={account?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                required
                maxLength={250}
              />
            </div>
            <div>
                <TelephoneInput
                    name="phone"
                    label="Phone number"
                    value={account.mobilePhone}
                    onChange={(e) => handleChange("mobilePhone", e.target.value)}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    required
                />
            </div>
            <div>
                <NumberInput
                    label={"Preferred Billing Day"}
                    name={"preferredPaymentDayOfMonth"}
                    value={account.preferredPaymentDayOfMonth}
                    onChange={(e) => { handleChange("preferredPaymentDayOfMonth", e.target.valueAsNumber || null) }}
                    placeholder={1}
                    min={1}
                    max={28}
                />
            </div>
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={"Save"}
            onClick={handleSave}
          />
        </div>
      </div>
      {isSysAdmin && (
        <div className="mt-8 bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
          <CollapsibleSection title="Account Membership">
            {!accountSubscription 
            ? (<div className="p-4">No current subscription available.</div>)
            : (
              <div className="flex flex-col p-4 gap-y-2">
                <div className="flex-grow p-2 rounded-lg bg-white border-2 border-slate-400">
                  <div className="flex flex-col gap-2">
                    <p className="text-lg font-semibold">{accountSubscription.name}</p>
                    <SubscriptionProperty propertyName={"Status"} propertyValue={accountSubscription.status}/>
                    <SubscriptionProperty propertyName={"Duration"} propertyValue={resolveOwnedProductDurationCount(accountSubscription.durationType, accountSubscription.durationCount)}/>
                    <SubscriptionProperty propertyName={"Effective At"} propertyValue={dateHelpers.localDateTime(accountSubscription.effectiveAt, dateHelpers.YMDHMSA)}/>
                    <SubscriptionProperty propertyName={"Expires At"} propertyValue={dateHelpers.localDateTime(accountSubscription.effectiveThrough, dateHelpers.YMDHMSA)}/>
                    <SubscriptionProperty propertyName={"Product Price"} propertyValue={formatCurrency(accountSubscription.fullProductPrice, true) }/>
                    <SubscriptionProperty propertyName={"Paid"} propertyValue={formatCurrency(accountSubscription.price, true)}/>
                    <SubscriptionProperty propertyName={"Quantity"} propertyValue={accountSubscription.qty}/>
                    <SubscriptionProperty propertyName={"Renewal Product"} propertyValue={hasRenewal ? accountSubscription.renewalProductName : "None"}/>
                    <SubscriptionProperty propertyName={"Renewal Product Price"} propertyValue={hasRenewal ? formatCurrency(accountSubscription.renewalProductPrice, true) : "None"}/>
                    {IS_DEV && <SubscriptionProperty propertyName={"Owned Product Id"} propertyValue={accountSubscription.ownedProductId}/>}
                    {IS_DEV && <SubscriptionProperty propertyName={"Product Id"} propertyValue={accountSubscription.productId}/>}
                  </div>
                </div>
                <div className="flex flex-col xl:flex-row gap-2 p-2">
                  <SubscriptionButton text={"Cancel Subscription"} properties={"bg-red-800 hover:bg-red-900"} onClick={() => setShowCancel(true)} />
                  <SubscriptionButton text={"Refund"} properties={canRefund ? "bg-fuchsia-700 hover:bg-fuchsia-900" : "bg-gray-400 text-black"} onClick={() => setShowRefund(true)} disabled={!canRefund}/>
                </div>
              </div>
            )}
          </CollapsibleSection>
          <CancelConfirmationModal
            showIcon={false}
            modalOpen={showCancel}
            setModalOpen={setShowCancel}
            title={"Cancel Subscription"}
            message={"Are you sure you want to cancel this subscription?"}
            textToMatch={currentUser?.username}
            confirmButtonText={"CONFIRM"}
            onConfirm={CancelSubscription}
            downgradeToFree={downgradeToFree}
            setDowngradeToFree={setDowngradeToFree}
          />
          <RefundConfirmationModal
            modalOpen={showRefund}
            setModalOpen={setShowRefund}
            title={"Refund Subscription"}
            message={"Are you sure you want to refund this subscription?"}
            textToMatch={currentUser?.username}
            confirmButtonText={"CONFIRM"}
            productPrice={accountSubscription?.price}
            onConfirm={RefundSubscription}
          />
        </div>
      )}
    </div>
  );
}

function SubscriptionProperty({propertyName, propertyValue}) {
  return (
  <div className="flex flex-row border-b-2 border-b-gray-100">
    <div className="basis-2/12">{propertyName}:</div>
    <div className="basis-10/12">{propertyValue}</div>
  </div>
  )
}

function SubscriptionButton({text, properties, onClick, disabled}) {
  return (
    <button 
      className={classNames("flex basis-1/12 rounded-xl p-2 justify-center items-center text-white hover:shadow-lg", properties)}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>);
}
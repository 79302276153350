import { create } from "zustand";
import { JsonTryParse, userHasRole } from "../utils/helpers";
import storage from "../utils/storage";
import constants from "../utils/constants";

const useUserStore = create((set, get) => ({
  currentUser: undefined,
  impersonating: false,
  systemTenants: [],
  activeSystemTenant: undefined,
  activeAccount: undefined,
  setCurrentUser: (currentUser) => set(() => ({ currentUser })),
  setImpersonating: (impersonating) => set(() => ({ impersonating })),
  impersonate: (user, token, systemTenants) => {
    if(get().impersonating) return;

    const storageCurrentUser = storage.getItem(storage.ITEMS.currentUser);
    const storageCurrentUserJWT = storage.getItem(storage.ITEMS.jwt);
    storage.setItem(storage.ITEMS.storageCurrentUser, storageCurrentUser);
    storage.setItem(storage.ITEMS.storageJWT, storageCurrentUserJWT);
    storage.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    storage.setItem(storage.ITEMS.jwt, token);
    storage.setItem(storage.ITEMS.systemTenants, JSON.stringify(systemTenants));
    set(() => ({ impersonating: true, currentUser: user, systemTenants: systemTenants }));
    if (systemTenants && systemTenants.length) {
      let activeSysTenant = systemTenants[0];
      set(() => ({ activeSystemTenant: activeSysTenant }));
      storage.setItem(storage.ITEMS.activeSystemTenant, JSON.stringify(activeSysTenant));
      if (activeSysTenant.accountId) {
        let tempActiveAccount = {
          id: activeSysTenant.accountId, 
          name: activeSysTenant.accountName
        };
        set(() => ({ activeAccount: tempActiveAccount}));        
        storage.setItem(storage.ITEMS.activeAccount, JSON.stringify(tempActiveAccount));
      }
    }
  },
  stopImpersonating: () => {
    const storageCurrentUser = storage.getItem(storage.ITEMS.storageCurrentUser);
    const storageCurrentUserJWT = storage.getItem(storage.ITEMS.storageJWT);
    storage.setItem(storage.ITEMS.currentUser, storageCurrentUser);
    storage.setItem(storage.ITEMS.jwt, storageCurrentUserJWT);
    storage.removeItem(storage.ITEMS.storageCurrentUser);
    storage.removeItem(storage.ITEMS.storageJWT);
    set(() => ({
      impersonating: false,
      currentUser: JsonTryParse(storageCurrentUser),
      systemTenants: [],
      activeSysTenant: undefined
    }));
    storage.removeItem(storage.ITEMS.systemTenants);
    storage.removeItem(storage.ITEMS.activeSystemTenant);
    storage.removeItem(storage.ITEMS.activeAccount);
    set(() => ({ systemTenants: undefined }));
    set(() => ({ activeSystemTenant: undefined }));
    set(() => ({ activeAccount: undefined }));
  },
  login: (user, token, rememberMe, systemTenants) => {
    set(() => ({ currentUser: user }));
    storage.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    storage.setItem(storage.ITEMS.jwt, token);
    if (rememberMe)
      storage.setItem(storage.ITEMS.username, user.username);
    else 
      storage.removeItem(storage.ITEMS.username);
    if (!userHasRole(user, constants.ROLE_IDS.SYS_ADMIN) && systemTenants && systemTenants.length) {
      let activeSysTenant = systemTenants[0];
      set(() => ({ systemTenants: systemTenants }));
      set(() => ({ activeSystemTenant: activeSysTenant }));
      if (activeSysTenant.accountId) {
        let tempActiveAccount = {
          id: activeSysTenant.accountId, 
          name: activeSysTenant.accountName
        };
        set(() => ({ activeAccount: tempActiveAccount}));        
        storage.setItem(storage.ITEMS.activeAccount, JSON.stringify(tempActiveAccount));
      }
      storage.setItem(storage.ITEMS.activeSystemTenant, JSON.stringify(activeSysTenant));
      storage.setItem(storage.ITEMS.systemTenants, JSON.stringify(systemTenants));
    }
  },
  logout: () => {
    set(() => ({ currentUser: undefined }));
    storage.removeItem(storage.ITEMS.currentUser);
    storage.removeItem(storage.ITEMS.jwt);
    storage.removeItem(storage.ITEMS.storageCurrentUser);
    storage.removeItem(storage.ITEMS.storageJWT);
    storage.removeItem(storage.ITEMS.systemTenants);
    storage.removeItem(storage.ITEMS.activeSystemTenant);
    storage.removeItem(storage.ITEMS.activeAccount);
    set(() => ({ impersonating: false }));
    set(() => ({ systemTenants: []}))
    set(() => ({ activeSystemTenant: undefined }))
  },
  setSystemTenants: (systemTenants) => set(() => ({ systemTenants })),
  setActiveSystemTenant: (activeSystemTenant) => {
    if (activeSystemTenant) {
      storage.setItem(storage.ITEMS.activeSystemTenant, JSON.stringify(activeSystemTenant));
    }
    else {
      storage.removeItem(storage.ITEMS.activeSystemTenant);
    }
    set(() => ({ activeSystemTenant }));
  },
  setActiveAccount: (newActiveAccount) => {
    if (newActiveAccount) {
      storage.setItem(storage.ITEMS.activeAccount, JSON.stringify(newActiveAccount));
    }
    else {
      storage.removeItem(storage.ITEMS.activeAccount);
    }
    set(() => ({ activeAccount: newActiveAccount }));
  }
}));

export default useUserStore;

import { Menu } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { MENU_ALT_2, MENU_ALT_3 } from "../../assets/images";
import { useLayoutStore, useUserStore } from "../../context";
import { useAlert } from "../../hooks";
import constants from "../../utils/constants";
import { classNames, isSystemAdmin } from "../../utils/helpers";

export default function TopNavMenu() {
  const { sidebarMenuOpen, setSidebarMenuOpen } = useLayoutStore();
  const { impersonating, stopImpersonating, currentUser, logout, activeSystemTenant } = useUserStore();
  const navigate = useNavigate();
  const alert = useAlert();
  const isSysAdmin = isSystemAdmin(currentUser);

  const handleStopImpersonation = () => {
    stopImpersonating();
    navigate("/");
    alert("Success", "No longer impersonating", "success");
  };

  const profileDropdownItems = useMemo(
    () => [
      {
        href: `/profile/${currentUser.id}`,
        name: "Profile",
      },
      {
        href: "/account",
        name: "Account",
      },
      {
        onClick: logout,
        href: "/",
        name: "Sign Out",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  return (
    <div className="z-50 mx-auto px-2 sm:px-6 lg:pl-4 lg:pr-8 bg-primaryColor  flex h-16 items-center justify-between fixed w-screen top-0 left-0">
      <div className="flex flex-1">
        {/* Sidebar opener/closer */}
        <div className="mr-6 flex items-center space-x-4 cursor-pointer">
          {sidebarMenuOpen ? (
            <img
              className="h-8 w-auto"
              src={MENU_ALT_3}
              alt="logo"
              onClick={() => setSidebarMenuOpen(false)}
            />
          ) : (
            <img
              className="h-8 w-auto"
              src={MENU_ALT_2}
              alt="logo"
              onClick={() => setSidebarMenuOpen(true)}
            />
          )}
        </div>

        {/* Logo  */}
        <Link to="/" className="flex flex-shrink-0 items-center">
          <img className="h-16 w-auto" src={constants.APP_LOGO} alt="logo" />
        </Link>
        <span className="ml-6 text-white text-lg font-semibold content-center">
          {!isSysAdmin && activeSystemTenant ? `(${activeSystemTenant.name})` : null}
        </span>

        {impersonating && (
          <div className="flex flex-shrink-0 items-center text-sm font-bold underline lg:ml-5 text-white cursor-pointer">
            <div onClick={handleStopImpersonation}>
              Stop impersonating {currentUser.username}
            </div>
          </div>
        )}
      </div>

      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3">
          <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primaryColor">
            <span className="sr-only">Open user menu</span>
            <UserCircleIcon className="w-10 text-white " />
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-50 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {profileDropdownItems.map((subItem, i) => (
              <Menu.Item key={subItem.href + i}>
                {({ active }) => (
                  <Link
                    to={subItem.href}
                    onClick={subItem.onClick}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    {subItem.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import { classNames, formatCurrency, isSystemAdmin, userHasRole } from "../utils/helpers";
import constants from "../utils/constants";
import { BarChart } from "../components/Charts";
import { ButtonGroup } from "../components/Buttons";
import dateHelpers from "../utils/dateHelpers";

function AnalyticsPage() {
    const { currentUser, activeSystemTenant, systemTenants, activeAccount } = useUserStore();
    const { fetch, post } = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useLoaderStore();
    const [subscriptionData, setSubscriptionData] = useState();
    const [financialData, setFinancialData] = useState();
    const [salesData, setSalesData] = useState();
    const [filteredSalesData, setFilteredSalesData] = useState();
    const [salesInterval, setSalesInterval] = useState(constants.SALES_INTERVAL_TYPES[0]?.value);
    const [salesMaximums, setSalesMaximums] = useState({
        amountMax: 1,
        countMax: 1,
        filteredAmountMax: 1,
        filteredCountMax: 1,
    })

    const isSysAdmin = userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN);
    useEffect(()=>{
        setShowLoader(true);
        if (activeSystemTenant || isSysAdmin) {
            getSubscriptionInfo();
            getFinancialInfo();
            GetProductSalesInfo({setResults: setSalesData});
        }
        setShowLoader(false);
    }, [activeSystemTenant]);

    useEffect(()=>{
        setShowLoader(true);
        if (activeSystemTenant || isSysAdmin) {
            GetProductSalesInfo({setResults: setFilteredSalesData, intervalType: salesInterval});
        }
        setShowLoader(false);
    }, [salesInterval]);

    useEffect(() => {
        let newMaxes = Object.assign({}, salesMaximums);
        if (salesData) {
            let amountMax = salesData.salesList.sort((a, b) => (b.soldAmount - a.soldAmount))[0]?.soldAmount;
            let countMax = salesData.volumeList.sort((a, b) => (b.soldCount - a.soldCount))[0]?.soldCount;
            newMaxes.amountMax = amountMax ? amountMax / 100  : 1;
            newMaxes.countMax = countMax ? countMax : 1;
        }
        if (filteredSalesData) {
            let filteredAmountMax = filteredSalesData.salesList.sort((a, b) => (b.soldAmount - a.soldAmount))[0]?.soldAmount;
            let filteredCountMax = filteredSalesData.volumeList.sort((a, b) => (b.soldCount - a.soldCount))[0]?.soldCount;
            newMaxes.filteredAmountMax = filteredAmountMax ? filteredAmountMax / 100  : 1;
            newMaxes.filteredCountMax = filteredCountMax ? filteredCountMax : 1;
        }
        console.log("new maxes", newMaxes);
        setSalesMaximums(newMaxes);
    }, [filteredSalesData])

    function getSubscriptionInfo() {
        let endpoint = isSysAdmin
            ? `admin/GetSubscriberInfo`
            : `admin/GetSubscriberInfo/${activeSystemTenant?.id}`;
        fetch(endpoint)
        .then(res => {
            // console.log("subscription info", res);
            setSubscriptionData(res);
        })
        .catch(err => {
            alert("Server error", err?.data?.message || "Error retrieving subscription information", "error")
        });
    }

    function getFinancialInfo() {
        let endpoint = isSysAdmin
            ? `admin/GetFinancialInfo`
            : `admin/GetFinancialInfo/${activeSystemTenant?.id}`;
        fetch(endpoint)
        .then(res => {
            // console.log("financial info", res);
            setFinancialData(res);
        })
        .catch(err => {
            alert("Server error", err?.data?.message || "Error retrieving financial information", "error")
        });
    }

    function GetProductSalesInfo({maxResults, intervalType, setResults}) {
        let [start, end] = ResolveStartEndDate(intervalType);
        let payload = {
            systemTenantId: isSysAdmin ? null : activeSystemTenant?.id,
            maxResults: maxResults || null,
            startDate: start || null,
            endDate: end || null
        };
        post("admin/GetProductSalesInfo", payload)
        .then(res => {
            // console.log("product sales info", res);
            setResults(res);
        })
        .catch(err => {
            alert("Server error", err?.data?.message || "Error retrieving sales information", "error")
        });
    }

    function ResolveStartEndDate(intervalType){
        let endMoment = moment();
        let end = endMoment.format(dateHelpers.YMD);
        let start = undefined;
        switch(intervalType){
            case constants.SALES_INTERVAL_IDS.THIRTY:
                start = endMoment.subtract(30, "days").format(dateHelpers.YMD);
                break;
            case constants.SALES_INTERVAL_IDS.SIXTY:
                start = endMoment.subtract(60, "days").format(dateHelpers.YMD);
                break;
            case constants.SALES_INTERVAL_IDS.NINETY:
                start = endMoment.subtract(90, "days").format(dateHelpers.YMD);
                break;
            case constants.SALES_INTERVAL_IDS.YEAR:
                start = endMoment.subtract(1, "year").format(dateHelpers.YMD);
                break;
        }
        end = !!start ? end : undefined;
        return [start, end];
    }

    function ResolveTitleInterval () {
        let label = constants.SALES_INTERVAL_TYPES.find(x => x.value === salesInterval)?.label;
        return label ? `(Last ${label})` : null;
    }

    return (
        <div>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <ButtonGroup
                        labelValueList={constants.SALES_INTERVAL_TYPES}
                        selectedElement={salesInterval}
                        setSelectedElement={setSalesInterval}
                    />
                </div>
                {filteredSalesData &&
                (<SectionCard title={`Best Selling Product By Volume ${ResolveTitleInterval()}`}>
                    <div className="px-4 flex flex-col basis-1/2">
                        <hr className="my-2"/>
                        <div className="flex flex-row font-semibold">
                            <div className="flex-1">Name</div>
                            <div className="flex-1">Amount Sold</div>
                        </div>
                        <ol className="list-decimal">
                            {filteredSalesData.volumeList.map((product, idx) => (
                                <li>
                                    <div className="flex flex-row py-2">
                                        <span className="flex-1">{product.name}</span>
                                        <span className="flex-1">{product.soldCount}</span>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                    <div className="flex basis-1/2">
                        <BarChart
                            // ticks={salesMaximums.filteredCountMax / 5}
                            data={
                                filteredSalesData.volumeList.map((product, idx) => ({label: product.name, value: product.soldCount}))
                            }
                            hideLabel
                        />
                    </div>
                </SectionCard>)}
                {filteredSalesData &&
                (<SectionCard title={`Best Selling Product By Gross Sales ${ResolveTitleInterval()}`}>
                    <div className="px-4  flex flex-col basis-1/2">
                        <hr className="my-2"/>
                        <div className="flex flex-row font-semibold">
                            <div className="flex-1">Name</div>
                            <div className="flex-1">Gross Amount</div>
                        </div>
                        <ol className="list-decimal">
                            {filteredSalesData.salesList.map((product, idx) => (
                                <li>
                                    <div className="flex flex-row py-2">
                                        <span className="flex-1">{product.name}</span>
                                        <span className="flex-1">{formatCurrency(product.soldAmount, true)}</span>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                    <div className="flex basis-1/2">
                        <BarChart
                            ticks={salesMaximums.filteredAmountMax / 10}
                            data={
                                filteredSalesData.salesList.map((product, idx) => ({label: product.name, value: product.soldAmount / 100}))
                            }
                            hideLabel
                        />
                    </div>
                </SectionCard>)}
                {salesData &&
                (<SectionCard title={"Best Selling Product By Volume"}>
                    <div className="px-4 flex flex-col basis-1/2">
                        <hr className="my-2"/>
                        <div className="flex flex-row font-semibold">
                            <div className="flex-1">Name</div>
                            <div className="flex-1">Amount Sold</div>
                        </div>
                        <ol className="list-decimal">
                            {salesData.volumeList.map((product, idx) => (
                                <li>
                                    <div className="flex flex-row py-2">
                                        <span className="flex-1">{product.name}</span>
                                        <span className="flex-1">{product.soldCount}</span>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                    <div className="flex basis-1/2 items-center">
                        <BarChart
                            // ticks={salesMaximums.countMax / 5}
                            data={
                                salesData.volumeList.map((product, idx) => ({label: product.name, value: product.soldCount}))
                            }
                            hideLabel
                        />
                    </div>
                </SectionCard>)}
                {salesData &&
                (<SectionCard title={"Best Selling Product By Gross Sales"}>
                    <div className="px-4 flex flex-col grow">
                        <hr className="my-2"/>
                        <div className="flex flex-row font-semibold">
                            <div className="flex-1">Name</div>
                            <div className="flex-1">Gross Amount</div>
                        </div>
                        <ol className="list-decimal">
                            {salesData.salesList.map((product, idx) => (
                                <li>
                                    <div className="flex flex-row py-2">
                                        <span className="flex-1">{product.name}</span>
                                        <span className="flex-1">{formatCurrency(product.soldAmount, true)}</span>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                    <div className="flex basis-1/2 justify-center items-center">
                        <BarChart
                            ticks={salesMaximums.amountMax / 10}
                            data={
                                salesData.salesList.map((product, idx) => ({label: product.name, value: product.soldAmount / 100}))
                            }
                            hideLabel
                        />
                    </div>
                </SectionCard>)}
                {subscriptionData && financialData &&
                    (<SectionCard title="Delinquent Summary">
                        <div className={classNames(
                            "flex flex-row grow text-2xl mt-4 gap-x-4 justify-center",
                            subscriptionData.delinquentSubscriberCount ? "text-red-700" : "text-gray-700"
                            )}>
                            <span>{`Count: ${subscriptionData.delinquentSubscriberCount}`}</span>
                            <span>{`Value: ${formatCurrency(financialData.currentDelinquentIncome, true)}`}</span>
                        </div>
                    </SectionCard>)}
                {subscriptionData &&
                    (<SectionCard title="Subscriber Summary">
                        <div className="flex flex-row grow text-2xl mt-4 gap-x-4 justify-center">
                            <span className={"text-gray-700"}>{`Count: ${subscriptionData.subscriberCount}`}</span>
                            <span className={classNames(subscriptionData.newSubscriberCount ? "text-green-500" : "text-gray-700")}>{`Gained: ${subscriptionData.newSubscriberCount}`}</span>
                            <span className={classNames(subscriptionData.lostSubscriberCount ? "text-red-700" : "text-gray-700")}>{`Lost: ${subscriptionData.lostSubscriberCount}`}</span>
                        </div>
                    </SectionCard>)}
                {financialData &&
                (<SectionCard title="Income Summary">
                    <div className="px-4 flex flex-col grow">
                        <div className="flex flex-row font-semibold">
                            <div className="basis-2/3">Income Category</div>
                            <div className="basis-1/3">Amount</div>
                        </div>
                        <div className="flex flex-row py-2 justify-between">
                            <span className="basis-2/3">Current Month's Actual Income</span>
                            <span className="basis-1/3">{formatCurrency(financialData.currentMonthIncome, true)}</span>
                        </div>
                        <div className="flex flex-row py-2">
                            <span className="basis-2/3">Current Month's Projected Income</span>
                            <span className="basis-1/3">{formatCurrency(financialData.currentMonthEstimatedIncome, true)}</span>
                        </div>
                        <div className="flex flex-row py-2">
                            <span className="basis-2/3">Next Month's Projected Income</span>
                            <span className="basis-1/3">{formatCurrency(financialData.nextMonthEstimatedIncome, true)}</span>
                        </div>
                    </div>
                    <div className="flex flex-col basis-1/2">
                        <BarChart
                            hideLabel
                            ticks={10}
                            data={[
                                {label: "Current Month's Actual Income", value: financialData.currentMonthIncome / 100},
                                {label: "Current Month's Projected Income", value: financialData.currentMonthEstimatedIncome / 100},
                                {label: "Next Month's Projected Income", value: financialData.nextMonthEstimatedIncome / 100}
                            ]}
                        />
                        <div className="mt-4 self-center text-gray-500">*Projected income based on expected renewals.</div>
                    </div>
                </SectionCard>)}
            </div>
        </div>
    );
}

function SectionCard({title, children, isCol}) {
    return (
        <div className="flex flex-col w-full h-min p-4 bg-white rounded-lg border-2 border-primaryColor/50">
            <div className="flex text-lg font-semibold justify-center items-center content-center">{title}</div>
            <div className={classNames("flex", isCol ? "flex-col" : "flex-row")}>
                {children}
            </div>
        </div>
    )
}

export default AnalyticsPage;
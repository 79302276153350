import React from 'react';
import {Tooltip} from "react-tooltip";

function InfoTooltip({id, children, place="bottom", width=40}) {

    return (
      <Tooltip
        anchorSelect={id}
        place={place}
        variant={"light"}
        offset={40}
        className={`max-w-[${width}] h-fit z-20 border drop-shadow `}
        noArrow
        style={{ width: `${40}%` }}
        opacity={1}
      >
        <div className={"break-normal font-semibold text-gray-700 m-1"}>
            {children}
        </div>
      </Tooltip>
    );
}

export default InfoTooltip;
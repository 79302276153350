import {
  CheckCircleIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, TabButton } from "../components/Buttons";
import { Pill } from "../components/Displays";
import { TelephoneInput, TextInput, SelectInput, PasswordInput } from "../components/Inputs";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import dateHelpers from "../utils/dateHelpers";
import { isValidEmail, isValidPassword } from "../utils/helpers";
import constants from "../utils/constants";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { LockFill, PersonLinesFill } from "react-bootstrap-icons"

export default function Profile() {
  let { id } = useParams();
  const { currentUser } = useUserStore();
  const { setShowLoader } = useLoaderStore();
  const { fetch, post } = useApi();
  const alert = useAlert();
  const navigate = useNavigate();

  const [user, setUser] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState("US");
  // const ct = require('countries-and-timezones');
  // const timezones = ct.getTimezonesForCountry('US');
  // const timezoneOptions = timezones.map((tz) => ({
  //     label: `${tz.name} (${tz.utcOffsetStr})`,
  //     value: tz.name
  // }));

  const timezoneOptions = constants.US_TIMEZONES;
  const TAB_NAMES = [{name: "Details", icon: <PersonLinesFill className="mr-1" aria-hidden="true"/>}, {name: "Change Password", icon: <LockFill className="mr-1" aria-hidden="true"/>}]
  const [activeTab, setActiveTab] = useState(TAB_NAMES.find(t => t.name)?.name);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  
  useEffect(() => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }, [activeTab]);

  useEffect(() => {
    if (currentUser.id !== Number(id)) {
      // profiles are only accessible to yourself (ie private)
      alert(
        "Error",
        "You do not have access to view this user's profile",
        "error"
      );
      navigate("/");
      return;
    }

    setShowLoader(true);
    fetch(`user/getUserInfo/${id}`)
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        alert("Server error", "Could not fetch user profile", "error");
      })
      .finally(() => setShowLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (property, value) => {
    setUser({
      ...user,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("user/updateProfile", { ...user, countryCode: selectedCountry })
      .then((res) => {
        alert("Success", "Saved profile", "success");
      })
      .catch((err) => {
        alert(
          "Unable to save user",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (
      !user.firstName ||
      !user.firstName.trim() ||
      user.firstName.trim().length <= 1
    ) {
      alert("Invalid input", "First name is not valid", "warning");
      return false;
    }

    if (
      !user.lastName ||
      !user.lastName.trim() ||
      user.lastName.trim().length <= 1
    ) {
      alert("Invalid input", "Last name is not valid", "warning");
      return false;
    }

    if (!user.email || !user.email.trim() || !isValidEmail(user.email)) {
      alert("Invalid input", "Email is not valid", "warning");
      return false;
    }

    if (
      !user.username ||
      !user.username.trim() ||
      user.username.trim().length <= 1
    ) {
      alert("Invalid input", "Username is not valid", "warning");
      return false;
    }

    if (!user.mobilePhoneNumber || !user.mobilePhoneNumber.trim()) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    if (
      !isPossiblePhoneNumber(user.mobilePhoneNumber.trim(), selectedCountry)
    ) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    return true;
  };
  
  const passwordChangeIsValid = () => {
    if (
      !currentPassword ||
      !currentPassword.trim() ||
      currentPassword.trim().length <= 1
    ) {
      alert("Invalid input", "Current password is not valid", "warning");
      return false;
    }
    
    if (
      !newPassword ||
      !newPassword.trim() ||
      newPassword.trim().length <= 1
    ) {
      alert("Invalid input", "New password is not valid", "warning");
      return false;
    }
    
    if (
      !confirmPassword ||
      !confirmPassword.trim() ||
      confirmPassword.trim().length <= 1
    ) {
      alert("Invalid input", "Password confirmation is not valid", "warning");
      return false;
    }

    if (newPassword !== confirmPassword) {
      alert("Invalid input", "New password and password confirmation do not match", "warning");
      return false;
    }

    if (!isValidPassword(newPassword.trim())) {
      alert("Invalid input", "New password is not complex enough", "warning");
      return false;
    }

    return true;
  };

  const handlePasswordChange = () => {
    if (!passwordChangeIsValid()) {
      return;
    }
    setShowLoader(true);
    let payload = {
      OldPassword: currentPassword,
      NewPassword: newPassword
    };
    post("user/UpdatePassword", payload)
    .then(res => {
      alert("Password updated", "Your password has been changed", "success");
    })
    .catch(err => {
      console.log("password error", err)
      let isValidationError = err?.data?.IsValidationError;
      let errorMessage = isValidationError ? err.data.Message : "An error occured while updating the password";
      let errorTitle = isValidationError ? "Error" : "Server error";
      alert(errorTitle, errorMessage, "error");
    })
    .finally(() => {
      setShowLoader(false);
    });
  }

  return (
    <div className="-m-4">
      {/* Header */}
      <div className="bg-white shadow-lg h-[30vh] flex items-center justify-center space-x-4">
        <div>
          <UserCircleIcon className="w-32 text-primaryColor" />
        </div>
        <div>
          <div className="text-xl font-semibold mb-2">
            {user?.firstName || currentUser.firstName}{" "}
            {user?.lastName || currentUser?.lastName}
          </div>
          <div className="text-gray-500">
            {user?.email || currentUser.email} |{" "}
            {user?.username || currentUser.username}
          </div>
          <div className="text-gray-500">
            {user?.timezone || currentUser?.timezone}
          </div>
        </div>
      </div>

      <div className="p-4">
        <Tab.Group>
          <Tab.List className={"flex flex-row"}>
            {TAB_NAMES.map((tab, i) => (
              <Tab as={Fragment}>
                {({selected}) => (
                  <TabButton
                    name={tab.name}
                    onClick={()=>{}}
                    selected={selected}
                    tabIcon={tab.icon}
                  />
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {/* Roles */}
              <div className="mt-6">
                <div className="space-x-2 space-y-2">
                  {currentUser.roles.map((x, i) => {
                    let roleName = constants.ROLES.find(r => r.value === x.typeOfRole)?.label || "";
                    return (
                      <Pill key={i} title={roleName} />
                    )})}
                </div>
              </div>

              {/* Info */}
              <div className="mt-6">
                <div className="">
                  <div className="flex text-gray-500">
                    Email verified{" "}
                    {currentUser.emailVerified === true ? (
                      <CheckCircleIcon className="w-6 ml-2 text-success" />
                    ) : (
                      <XCircleIcon className="w-6 ml-2 text-error" />
                    )}
                  </div>
                  <div className="flex text-gray-500">
                    Last logged on{" "}
                    <div className="text-primaryColor ml-2">
                      {dateHelpers.getRelativeTime(currentUser.lastLoggedOnAt)}
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  {/* Form */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                    <div>
                      <TextInput
                        label="First Name"
                        value={user?.firstName ?? ""}
                        onChange={(e) => handleChange("firstName", e.target.value)}
                        maxLength={30}
                        required
                      />
                    </div>
                    <div>
                      <TextInput
                        label="Last Name"
                        value={user?.lastName ?? ""}
                        onChange={(e) => handleChange("lastName", e.target.value)}
                        maxLength={30}
                        required
                      />
                    </div>
                    <div>
                      <TextInput
                        label="Email"
                        value={user?.email ?? ""}
                        onChange={(e) => handleChange("email", e.target.value)}
                        maxLength={50}
                        required
                      />
                    </div>
                    <div>
                      <TextInput
                        label="Username"
                        value={user?.username ?? ""}
                        onChange={(e) => handleChange("username", e.target.value)}
                        maxLength={30}
                        required
                      />
                    </div>
                    <div>
                      <TelephoneInput
                        name="phone"
                        label="Phone number"
                        value={user?.mobilePhoneNumber ?? ""}
                        onChange={(e) =>
                          handleChange("mobilePhoneNumber", e.target.value)
                        }
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        required
                      />
                    </div>
                    <div>
                      <SelectInput
                        label={"Timezone"}
                        options={timezoneOptions}
                        value={timezoneOptions.find(x => x.value === user?.timezone)}
                        onChange={(e) => {handleChange("timezone", e.value)}}
                        onClear={() => {handleChange("timezone", null)}}
                        isSearchable
                      />
                    </div>
                  </div>

                  {/* Submit button */}
                  <div className="mt-12">
                    <PrimaryButton text="Save" onClick={handleSave} />
                  </div>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-6">
                <div className="w-1/4 my-4">
                  <PasswordInput
                    label="Current Password"
                    name="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="w-1/4 my-4">
                  <PasswordInput
                    label="New Password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    showInfo
                    required
                  />
                </div>
                <div className="w-1/4 my-4">
                  <PasswordInput
                    label="Confirm New Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="w-1/4 mt-12">
                  <PrimaryButton text="Save" onClick={handlePasswordChange}/>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

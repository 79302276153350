import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { PasswordInput } from "../components/Inputs";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import { isValidPassword } from "../utils/helpers";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";
import constants from "../utils/constants";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import storage from "../utils/storage";


export default function EmailVerification() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { post } = useApi();
  const { token, redirectToMarkt } = useParams();


  const [loading, setLoading] = useState(true);
  const [emailSuccesfullyVerified, setEmailSuccessfullyVerified] = useState(false);

  useEffect(() => {
    verifyEmail()
  }, [token])


  const verifyEmail = async () => {

    await post("public/verifyEmail", token)
      .then((res) => {
        storage.setItem(storage.ITEMS.username, res.username);
        alert("Success!", "Verified email address", "success");
        setEmailSuccessfullyVerified(true);
        if (!!redirectToMarkt) {
          window.location.href = constants.MARKT_ADDRESS;
          return
        }
        navigate("/")
      })
      .catch((err) => {
        alert("Unsuccessful email verification attempt", "Could not verify email", "error");
        console.error(err);
        setEmailSuccessfullyVerified(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <h1 className="text-3xl font-bold text-primaryColor">Email Verification</h1>
      <h5 className="mt-1 mb-6">
        Or{" "}
        <Link to="/" className="text-primaryColor hover:text-primaryColor/25">
          back to sign in
        </Link>
      </h5>

      <div className="w-full space-y-6 flex justify-center items-center mt-14">
        {loading ?
          <ClipLoader
            color={constants.APP_PRIMARY_COLOR}
            size={200}
            speedMultiplier={0.5}
          />
          :
          emailSuccesfullyVerified ?
            <CheckCircleIcon className="text-success w-40"/>
          :
            <XCircleIcon className="text-error w-40"/>
        }
      </div>
    </>
  );
}

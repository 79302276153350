import { useState } from "react";
import { useRegistrationStore } from "../../context";
import { useAlert } from "../../hooks";
import { TextInput } from "../Inputs";
import { isValidEmail } from "../../utils/helpers";
import { PrimaryButton } from "../Buttons";
import constants from "../../utils/constants";

function AccountRegistrationForm(){
    const { registrationUser, setRegistrationAccount, setCurrentStep } = useRegistrationStore();
    const alert = useAlert();
    const [name, setName] = useState("");
    const [accountEmail, setAccountEmail] = useState(registrationUser?.email || "");
    const [accountMobilePhone, setAccountMobilePhone] = useState(registrationUser?.mobilePhoneNumber || "");

    function isValid(){
        if (!name || !name.trim()) {
            alert("Invalid input", "Please enter a valid name", "warning");
            return false;
        }
        if (!accountEmail || !accountEmail.trim() || !isValidEmail(accountEmail)) {
            alert("Invalid input", "Please enter a valid email", "warning");
            return false;
        }
        if (!accountMobilePhone || !accountMobilePhone.trim()) {
            alert("Invalid input", "Please enter a valid name", "warning");
            return false;
        }
        return true;
    }

    const handleSave = async () => {
        if (!isValid()) return;
        setRegistrationAccount({
            name: name,
            email: accountEmail,
            mobilePhone: accountMobilePhone
        });
        setCurrentStep(constants.REGISTRATION_STEPS.SYSTEM_TENANT);
    };

    return (
        <div className="w-full space-y-6">
            <div className="text-primaryColor text-lg font-semibold flex flex-row justify-start border-b">Account Information</div>
            <div>
                <TextInput
                    label="Name"
                    name="name"
                    value={name}
                    maxLength={200}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div>
                <TextInput
                    label="Account Email"
                    name="accountEmail"
                    value={accountEmail}
                    maxLength={150}
                    onChange={(e) => setAccountEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <TextInput
                    label="Account Mobile Phone"
                    name="accountMobilePhone"
                    value={accountMobilePhone}
                    maxLength={25}
                    onChange={(e) => setAccountMobilePhone(e.target.value)}
                    required
                />
            </div>
            <PrimaryButton text="NEXT: Register Tenant Information" onClick={handleSave} />
        </div>
    )
}
export default AccountRegistrationForm;
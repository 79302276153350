export default function CheckboxInput({
  label,
  subLabel,
  checked,
  onChange,
  name,
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5 ">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="cursor-pointer rounded border-none focus:ring-primaryColor h-4 w-4 border-gray-300 accent-primaryColor"
          checked={checked}
          onChange={onChange}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label htmlFor={name} className="font-normal text-gray-700">
            <span>{label}</span>
          </label>
          {subLabel && <p className="text-gray-400">{subLabel}</p>}
        </div>
      )}
    </div>
  );
}

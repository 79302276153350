import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { classNames } from "../../utils/helpers";
import { PaginationOptions } from "./";

export default function Paginator({
  count,
  display,
  currentPage,
  setDisplay,
  setCurrentPage,
}) {
  const paginationHandler = (e) => {
    const value = e.currentTarget.textContent;
    if (value === "Previous" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (value === "Next") {
      setCurrentPage(currentPage + 1);
    } else if (!Number.isNaN(value)) {
      const pageNumber = Number(value);
      setCurrentPage(pageNumber);
    }
  };

  const numPages = Math.ceil(count / (display || 1));

  // This is just setting up the display with dots and numbers
  const pagination = (currentPage, numPages) => {
    let current = currentPage,
      last = numPages,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;
    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }
    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return rangeWithDots;
  };

  const [numSelectors, setNumSelectors] = useState(
    pagination(currentPage, numPages)
  );

  useEffect(() => {
    const currentSelectors = pagination(currentPage, numPages);
    setNumSelectors(currentSelectors);
  }, [currentPage, numPages]);

  return (
    <nav className="px-4 pb-2 sm:px-0">
      <div className="flex items-center justify-between">
        {/* Left arrow */}
        <div className="-mt-px w-0 flex-1 flex">
          <button
            onClick={currentPage > 1 ? paginationHandler : () => {}}
            className={classNames(
              currentPage > 1
                ? "text-gray-500 hover:text-gray-700 hover:border-gray-300"
                : "text-gray-300",
              "border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium "
            )}
          >
            <ArrowLongLeftIcon
              className={classNames(
                currentPage > 1 ? "text-gray-400" : "text-gray-300",
                "mr-3 h-5 w-5"
              )}
              aria-hidden="true"
            />
            Previous
          </button>
        </div>
        {/* Dots/numbers */}
        <div className="hidden md:-mt-px md:flex">
          {numSelectors.map((selector, selectorIdx) => {
            if (
              selector === currentPage ||
              (!!!currentPage && selector === 1)
            ) {
              return (
                <div
                  key={`selector-${selectorIdx}`}
                  className="border-primaryColor text-primaryColor border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                  aria-current="page"
                >
                  {selector}
                </div>
              );
            } else if (selector === "...") {
              return (
                <span
                  key={`selector-${selectorIdx}`}
                  className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                >
                  ...
                </span>
              );
            } else
              return (
                <button
                  onClick={paginationHandler}
                  className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                  key={`selector-${selectorIdx}`}
                >
                  {selector}
                </button>
              );
          })}
        </div>
        {/* Right arrow */}
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <button
            onClick={
              currentPage < numPages || (!!!currentPage && numPages > 1)
                ? paginationHandler
                : () => {}
            }
            className={classNames(
              currentPage < numPages || (!!!currentPage && numPages > 1)
                ? "text-gray-500 hover:text-gray-700 hover:border-gray-300"
                : "text-gray-300",
              "border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium"
            )}
          >
            Next
            <ArrowLongRightIcon
              className={classNames(
                currentPage < numPages || (!!!currentPage && numPages > 1)
                  ? "text-gray-400"
                  : "text-gray-300",
                "ml-3 h-5 w-5 "
              )}
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
      {setDisplay && (
        <PaginationOptions
          total={count}
          rowCount={display}
          currentPage={currentPage}
          setRowCount={setDisplay}
        />
      )}
    </nav>
  );
}

import { LOGO } from "../assets/images";

const APP_NAME = "Margot Okapi";
const APP_PRIMARY_COLOR = "#253da1";
const APP_SLOGAN =
  "This is a placeholder slogan. Have the client write something cool here.";
const APP_LOGO = LOGO;
const MARKT_ADDRESS = "https://markt-app.azurewebsites.net";
const DEV_DOMAIN = "localhost";

const MARKT_MONTHLY_MEMBERSHIP_ID = -4;

const SORT_DIRECTIONS = {
  ASCENDING: "ASC",
  DESCENDING: "DESC",
};

const DISPLAY_PER_PAGE = 25;

const ROLES = [
  { label: "System Administrator", value: 1 },
  { label: "Administrator", value: 2 },
];

const ROLE_IDS = {
  SYS_ADMIN: 1,
  ADMIN: 2
};

const NOTIFICATION_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

const SYS_TENANT_TABS = {
  PROFILE: "Profile Info",
  ACTIVITY: "Activity",
  NOTIFICATIONS: "Notifications"
};

const PRODUCT_TYPES = [
  { label: "Select Product Type", value: undefined},
  { label: "Primary License", value: 1},
  { label: "Feature Supplement", value: 2},
  { label: "Item", value: 3}
];

const DURATION_TYPES = [
  { label: "Unknown", value: 0},
  { label: "Minutes", value: 5},
  { label: "Hours", value: 10},
  { label: "Days", value: 15},
  { label: "Months", value: 20},
  { label: "Years", value: 30},
];

const INTERVAL_TYPES = [
  { label: "Day", value: 1},
  { label: "Week", value: 2},
  { label: "Month", value: 3},
  { label: "Year", value: 4},
  { label: "YTD", value: 5},
];

const SALES_INTERVAL_TYPES = [
  { label: "30 Days", value: 1},
  { label: "60 Days", value: 2},
  { label: "90 Days", value: 3},
  { label: "Year", value: 4},
];

const SALES_INTERVAL_IDS = {
  THIRTY: 1,
  SIXTY: 2,
  NINETY: 3,
  YEAR: 4,
};

const MIME_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS: "application/vnd.ms-excel",
  PDF: "application/pdf",
  JPEG: "image/jpeg",
  PNG: "image/png",
  GIF: "image/gif",
};

const MAX_PAYMENT_TYPES = 3;

const US_TIMEZONES = [
  { label: "Eastern Standard Time", value: "Eastern Standard Time"},
  { label: "Central Standard Time", value: "Central Standard Time"},
  { label: "US Mountain Standard Time", value: "US Mountain Standard Time"},
  { label: "Mountain Standard Time", value: "Mountain Standard Time"},
  { label: "Pacific Standard Time", value: "Pacific Standard Time"},
  { label: "Alaskan Standard Time", value: "Alaskan Standard Time"},
];

const REGISTRATION_STEPS = {
  USER: {name: "User", seq: 1, label: "User"},
  ACCOUNT: {name: "Account", seq: 2, label: "Account"},
  SYSTEM_TENANT: {name: "SystemTenant", seq: 3, label: "System Tenant"},
};

const STEPS_LIST = [
  REGISTRATION_STEPS.USER,
  REGISTRATION_STEPS.ACCOUNT,
  REGISTRATION_STEPS.SYSTEM_TENANT,
];

const REFUND_REASONS = [
  { label: "Requested By Customer", value: "RequestedByCustomer" },
  { label: "Duplicate", value: "Duplicate" },
  { label: "Fraudulent", value: "Fraudulent" },
];

const EXPLANATIONS = {
  Renew: `This defines the window of time prior to primary subscription expiration during which automatic renewal payments will be attempted.
      Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he'd like to renew.
      We will begin attempting to process his payment on file per his intent this number of minutes prior to that expiration.
      If this setting is 1440 (aka 1 day) then the attempt to purchase the renewal subscription will occur a bit after 8AM on Thursday.
      It is recommended that at least 2 or 3 days of time be set here so that any payment issues can be corrected prior to license expiration. (Default set to 4 days)`,
  WarnCanel: `This defines the window of time prior to primary subscription expiration during which a pending expiration/cancellation notification will be sent to someone NOT intending to renew.
Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he will not be renewing to any subscription product.
We may send a cancellation notification to him according to his defined preferences beginning at Monday at 8AM if this setting is 7200 (aka 5 days). (Default set to 7 days)`,
  WarnRenewal: `This defines the window of time prior to primary subscription expiration during which a pending renewal notification will be sent to someone intending to renew.
Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he'd like to renew.
We may send a renewal notification to him according to his defined preferences beginning at Monday at 8AM if this setting is 7200 (aka 5 days).
Note that this warning of renewal notification will only ever be sent once, at most, per subscription renewal.
It is also necessary to choose a setting here that is more than the Process Renewal Minutes Threshold value because we no longer attempt to
warn of pending renewal once the renewal processing window threshold has passed.
For instance, if the Process Renewal Minutes Threshold is 3 days and the Warning of Renewal Minutes Threshold is 4 days only the 1 day of additional
anticipation time will be considered usable for sending the warning notification. (Default set to 7 days)`,
  Grace: `This is the number of extra minutes of access permitted after a primary subscription license has expired before the expired license is actually cancelled.
A scenario in which this would be relevant is a failed renewal payment.
Say Tom's payment instrument on file is declined when it was processed for a renewal 2880 minutes (aka 2 days) prior to its expiration.
(By the way, that 2880 minutes is the Process Renewal Threshold Minutes setting.) Tom will receive a delinquent notification and given a chance to
provide a working payment instrument for this period of time before he loses his subscription and his access.
Note that we will only re-attempt delinquent payments once every 24 hours. (Default set to 4 days)`,
  EmailNotification: `This is an email address used only when a notification or other processing failure occurs for this account. Email will be used preferentially if it is provided.`,
  MobileNotification: `This is a mobile phone number used only when a notification or other processing failure occurs for this account. Email will be used preferentially if it is provided.`,
  GetTenantUsers: `This is a defined API endpoint within the tenant's application that is called by Margot Okapi when tenant users are needed for notification purposes. More information can be found in documentation after registration.`,
  CommunicationSent: `This is a defined API endpoint within the tenant's application that is called by Margot Okapi when any communication has been sent to tenant users via email or sms. It delivers a record of the sent communication to a tenant user. More information can be found in documentation after registration. `,
  Signature: `Optional signature appended to the end of any sms or email.`
}

const constants = {
  APP_NAME,
  APP_SLOGAN,
  APP_PRIMARY_COLOR,
  APP_LOGO,
  DISPLAY_PER_PAGE,
  DEV_DOMAIN,
  DURATION_TYPES,
  EXPLANATIONS,
  INTERVAL_TYPES,
  MAX_PAYMENT_TYPES,
  NOTIFICATION_TYPES,
  PRODUCT_TYPES,
  SALES_INTERVAL_IDS,
  SALES_INTERVAL_TYPES,
  SORT_DIRECTIONS,
  SYS_TENANT_TABS,
  ROLES,
  ROLE_IDS,
  MIME_TYPES,
  MARKT_ADDRESS,
  US_TIMEZONES,
  MARKT_MONTHLY_MEMBERSHIP_ID,
  REFUND_REASONS,
  REGISTRATION_STEPS,
  STEPS_LIST
};

export default constants;

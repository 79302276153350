import { UserGroupIcon, QuestionMarkCircleIcon, CreditCardIcon, BanknotesIcon, GiftTopIcon, GiftIcon, KeyIcon, UserCircleIcon, AdjustmentsHorizontalIcon, UsersIcon, DocumentTextIcon, ChartBarSquareIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { InfoCard } from "../components/Displays";
import { useUserStore } from "../context";
import useSWR from "swr";
import { useAlert, useApi } from "../hooks";
import { isSystemAdmin, resolveSystemTenantId } from '../utils/helpers';
import constants from "../utils/constants";
import { useEffect, useState } from "react";

export default function Dashboard() {
  const { currentUser, activeSystemTenant, systemTenants, activeAccount } = useUserStore();
  const { fetch, post } = useApi();
  const alert = useAlert();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const { data, isLoading, mutate } = useSWR(
    `admin/dashboardInfo/${activeAccount?.id || ""}/${resolveSystemTenantId(currentUser, activeSystemTenant)}` ,
    fetch
  );

  const isSysAdmin = isSystemAdmin(currentUser);
  const showCreateCustomer = !isSysAdmin && !data?.hasStripeAccount;
  const showManageAccount = !isSysAdmin && data?.hasStripeAccount;

  function handleNavigateToSystemTenants() {
    navigate("/systemTenants");

    //WILL USE LATER - User can create more system tenants with paid membership
    // if(userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN) || systemTenants.length !== 1) {
    //   navigate("/systemTenants");
    //   return;
    // }
    // navigate(`/systemTenants/${activeSystemTenant.id}`)
  }
  function createStripeAccount(){
    if(isSaving) return;
    setIsSaving(true);
    post(`admin/CreateSystemAccountStripeCustomer/${activeAccount.id}`)
    .then(res => {
      alert("Success", "Stripe Account Created", "success");
      mutate();
    })
    .catch(err => {
      alert("Error", "Error creating stripe account", "error");
    })
    .finally(()=>{
      setIsSaving(false);
    });
  }

  useEffect(()=>{
    if(activeSystemTenant) {
      getSubscriptionInfo();
      getFinancialInfo();
    }
  }, [activeSystemTenant]);

  function getSubscriptionInfo() {
    fetch(`admin/GetSubscriberInfo/${activeSystemTenant?.id}`)
    .then(res => 
      console.log("subscription info", res)
    )
    .catch(err => {
      alert("Server error", err?.data?.message || "Error retrieving subscription information", "error")
    });
  }

  function getFinancialInfo() {
    fetch(`admin/GetFinancialInfo/${activeSystemTenant?.id}`)
    .then(res => 
      console.log("financial info", res)
    )
    .catch(err => {
      alert("Server error", err?.data?.message || "Error retrieving financial information", "error")
    });
  }

  return (
    <div className="-m-4">
      {/* Header */}
      <div className="bg-primaryColor w-full h-[20vh] p-4 text-lg">
        <h3 className="text-white font-bold">
          Welcome, {currentUser.firstName} 
        </h3>

        {/* Put relevant info to client in this section */}
      </div>
      {/* Cards */}
      <div className="flex flex-wrap p-4">
        <InfoCard
          title="Manage Users"
          count={data?.userCount}
          onClick={() => navigate("/users")}
          Icon={UserGroupIcon}
          isLoading={isLoading}
        />
        {isSysAdmin
          ? (<InfoCard
              title="Manage System Tenant Accounts"
              // count={data?.ownedProductCount}
              onClick={() => navigate("/systemAccounts")}
              Icon={UsersIcon}
              isLoading={isLoading}
            />)
          : null
        }
        {activeAccount
          ? (<InfoCard
            title="Manage Account"
            // count={data?.ownedProductCount}
            onClick={() => navigate(`/systemAccounts/${activeAccount.id}`)}
            Icon={UserCircleIcon}
            isLoading={isLoading}
          />)
          : null
        }
        {showCreateCustomer &&
        (<InfoCard
          title="Create Stripe Account"
          // count={data?.ownedProductCount}
          onClick={createStripeAccount}
          Icon={CreditCardIcon}
          isLoading={isLoading}
        />)}
        {showManageAccount &&
        (<InfoCard
          title="Manage Account Plan"
          // count={data?.ownedProductCount}
          onClick={() => navigate("/account")}
          Icon={CreditCardIcon}
          isLoading={isLoading}
        />)}
        <InfoCard
          title={data?.systemTenantCount === 1 ? "Manage System Tenant" : "Manage System Tenants"}
          count={data?.systemTenantCount}
          onClick={handleNavigateToSystemTenants}
          Icon={AdjustmentsHorizontalIcon}
          isLoading={isLoading}
        />
        <InfoCard
          title="Manage System Tenant Api Keys"
          count={data?.systemTenantApiKeyCount}
          onClick={() => navigate("/systemTenantApiKeys")}
          Icon={KeyIcon}
          isLoading={isLoading}
        />
        <InfoCard
          title="Manage Products"
          count={data?.productCount}
          onClick={() => navigate("/products")}
          Icon={GiftIcon}
          isLoading={isLoading}
        />
        <InfoCard
          title="Manage Owned Products"
          count={data?.ownedProductCount}
          onClick={() => navigate("/ownedProducts")}
          Icon={GiftTopIcon}
          isLoading={isLoading}
        />
        <InfoCard
          title="Manage Payments"
          count={data?.paymentCount}
          onClick={() => navigate("/payments")}
          Icon={BanknotesIcon}
          isLoading={isLoading}
        />
        <InfoCard 
          title="Transaction Reporting"
          onClick={() => navigate("/transactionReporting")}
          Icon={DocumentTextIcon}
          isLoading={isLoading}
        />  
        {(activeSystemTenant || isSysAdmin) &&
        (<InfoCard 
          title="Analytics"
          onClick={() => navigate("/analytics")}
          Icon={ChartBarSquareIcon}
          isLoading={isLoading}
        />)}
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue, resolveSystemTenantId, isDev } from "../utils/helpers";

export default function Product() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, activeSystemTenant } = useUserStore();

  const [product, setProduct] = useState({ id });

  const [systemTenantIdData, setSystemTenantIdData] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [durationTypes, setDurationTypes] = useState([]);
  const IS_DEV = isDev();

  useEffect(() => {
    getSystemTenantsSimple();
    getProductTypes();
    getDurationTypes();
    getProduct();
  }, []);

  function getProduct(){
    if (isCreatingNew) return;
    setShowLoader(true);
    fetch(`admin/productInfo/${id}/${resolveSystemTenantId(currentUser, activeSystemTenant)}`)
    .then((res) => {
      setProduct(res);
    })
    .catch(() => {
      alert("Server error", "Could not retrieve product", "error");
    })
    .finally(() => setShowLoader(false));
  }

  function getSystemTenantsSimple() {
    fetch("admin/getSystemTenantsSimple", { sortField: "Name", id: resolveSystemTenantId(currentUser, activeSystemTenant) })
    .then((res) => {
        setSystemTenantIdData(res);
    });
  }
  
  function getProductTypes() {
    fetch("Reference/GetProductTypes")
    .then(res => {
      setProductTypes(convertToLabelValueList(res));
    })
    .catch(err => {
      alert("Error", "Unable to fetch product types", "danger")
    })
  }

  function getDurationTypes() {
    fetch("Reference/GetDurationTypes")
    .then(res => {
      setDurationTypes(convertToLabelValueList(res));
    })
    .catch(err => {
      alert("Error", "Unable to fetch duration types", "danger")
    })
  }

  const handleChange = (property, value) => {
    setProduct({
      ...product,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("admin/saveProduct", product)
      .then((res) => {
        alert("Success", "Saved product", "success");
        navigate("/products");
      })
      .catch((err) => {
        alert(
          "Unable to save product",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!product.systemTenantId) {
      alert("Form invalid", "System Tenant Id must have a value", "warning");
      return false;
    }
    if (Number.isNaN(product.price) || product.price < 0) {
      alert("Form invalid", "Price must have a value", "warning");
      return false;
    }
    if (!product.name || !product.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!product.productType) {
      alert("Form invalid", "Product Type Id must have a value", "warning");
      return false;
    }
    // add validations here

    return true;
  };
  
  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !product ? "Create new product" : `Manage product ${IS_DEV ? product.id : ""}`
        }
      />
      <GoBack display="Manage all products" navigateTo="/products" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <SelectInput
                label="System Tenant Id"
                options={convertToLabelValueList(systemTenantIdData)}
                onClear={() => handleChange("systemTenantId", undefined)}
                value={convertToLabelValue(systemTenantIdData?.find(x => x.id === product?.systemTenantId))}
                onChange={(systemTenantId) => handleChange("systemTenantId", systemTenantId.value)}
                isSearchable
              />
            </div>
            <div>
              <NumberInput
                label="Price"
                value={product?.price ?? ""}
                onChange={(e) => handleChange("price", e.target.valueAsNumber)}
                required
              />
            </div>
            <div>
              <TextInput
                label="Name"
                value={product?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={250}
              />
            </div>
            <div>
              <SelectInput
                label="Duration Type"
                options={durationTypes}
                onClear={() => handleChange("durationType", undefined)}
                value={durationTypes.find(x => x.value === product?.durationType)}
                onChange={(e) => handleChange("durationType", e.value)}
                isSearchable
              />
            </div>
            <div>
              <NumberInput
                label="Duration Amount"
                value={product?.durationCount ?? ""}
                onChange={(e) => handleChange("durationCount", e.target.valueAsNumber)}
                min={0}
              />
            </div>
            <div>
              <SelectInput
                label="Product Type"
                options={productTypes}
                onClear={() => handleChange("productType", undefined)}
                value={productTypes.find(x => x.value === product?.productType)}
                onChange={(e) => handleChange("productType", e.value)}
                isSearchable
              />
            </div>
            <div>
              <NumberInput
                label="Supplemental Feature Qty"
                value={product?.supplementalFeatureQty ?? ""}
                onChange={(e) => handleChange("supplementalFeatureQty", e.target.valueAsNumber)}
                min={0}
              />
            </div>
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}

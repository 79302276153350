import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNotificationStore } from "../../context";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";

export default function Notification() {
  const loadingBarRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const { open, title, body, autoClose, type, setOpen } =
    useNotificationStore();

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      const loadingBarElement = loadingBarRef.current;

      if (loadingBarElement?.classList?.contains("restart-loading")) {
        // Use requestAnimationFrame to force a repaint
        requestAnimationFrame(() => {
          loadingBarElement.classList.remove("restart-loading");
        });
      }

      requestAnimationFrame(() => {
        loadingBarElement?.classList?.add("restart-loading");
      });
    }

    if (autoClose) {
      if (open) {
        const timeout = setTimeout(() => {
          setOpen(false);
        }, 4000); // 4s to close ... if change this value remember to change within App.cs .loading-bar

        setTimeoutId(timeout);
      } else {
        clearTimeout(timeoutId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, autoClose, title, body, type]);

  return (
    <div className="fixed inset-0 flex items-end px-4 py-8 pointer-events-none z-50 ">
      <div className="w-full flex flex-col items-center space-y-4 relative">
        <Transition
          show={open}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-x-0 translate-y-full opacity-0"
          enterTo="translate-x-0 translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative max-w-md max-h-56 no-scrollbar overflow-y-auto w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {type === constants.NOTIFICATION_TYPES.SUCCESS && (
                    <CheckCircleIcon
                      className="h-6 w-6 text-success"
                      aria-hidden="true"
                    />
                  )}
                  {type === constants.NOTIFICATION_TYPES.WARNING && (
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-warning"
                      aria-hidden="true"
                    />
                  )}
                  {type === constants.NOTIFICATION_TYPES.ERROR && (
                    <XCircleIcon
                      className="h-6 w-6 text-error"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                  <p className="mt-1 text-sm text-gray-500">{body}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div
              ref={loadingBarRef}
              className={classNames(
                "sticky bottom-0 h-1 w-full",
                type === constants.NOTIFICATION_TYPES.SUCCESS
                  ? "bg-success"
                  : "",
                type === constants.NOTIFICATION_TYPES.WARNING
                  ? "bg-warning"
                  : "",
                type === constants.NOTIFICATION_TYPES.ERROR ? "bg-error" : "",

                autoClose ? "transform duration-1000 animate loading-bar" : ""
              )}
            ></div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

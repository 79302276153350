function ButtonGroup({labelValueList, selectedElement, setSelectedElement}) {
    if(!Array.isArray(labelValueList)) return null;
    const selectStyle = "z-10 ring-2 ring-primaryColor text-primaryColor";
    return (
        <div class="inline-flex rounded-md">
          {labelValueList.map((element, idx) => {
            let isSelected = element.value === selectedElement;
            if(idx === 0) {
              return (
                <button 
                  type="button" 
                  class={`rounded-l-lg border-t border-b border-l border-gray-200 bg-white text-sm font-medium px-4 py-2 hover:bg-gray-100 hover:text-primaryColor ${isSelected ? selectStyle : ""}`}
                  onClick={()=>{setSelectedElement(element.value)}}
                >
                  {element.label}
                </button>
              );
            }
            else if(idx === labelValueList.length - 1) {
              return (
                <button 
                  type="button" 
                  class={`rounded-r-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 hover:bg-gray-100 hover:text-primaryColor ${isSelected ? selectStyle : ""}`}
                  onClick={()=>{setSelectedElement(element.value)}}
                >
                  {element.label}
                </button>
              );
            }
            return (
              <button 
                type="button" 
                class={`border-t border-b border-l border-gray-200 bg-white text-sm font-medium px-4 py-2 hover:bg-gray-100 hover:text-primaryColor ${isSelected ? selectStyle : ""}`}
                onClick={()=>{setSelectedElement(element.value)}}
              >
              {element.label}
            </button>
          )})}
        </div>
    );
}

export default ButtonGroup;
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import constants from "../../utils/constants";

export default function PublicLayout() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen relative h-full flex flex-col lg:flex-row bg-backgroundColor">
      {/* <div
        onClick={() => navigate("/")}
        className="w-full cursor-pointer relative lg:w-2/5 flex flex-col justify-center items-center px-4 py-8 transition-all duration-500 bg-gradient-to-tl to-primaryColor via-primaryColor/70 from-primaryColor bg-size-200 bg-pos-0 hover:bg-pos-100"
      >
        <img
          height="200"
          className="w-auto"
          src={constants.APP_LOGO}
          alt="logo"
        />
        <h2 className="text-4xl font-black text-white mt-2 text-center">
          {constants.APP_NAME}
        </h2>
        <h3 className="text-white text-xl font-normal text-center mt-4 w-8/12 mx-auto mb-20 ">
          {constants.APP_SLOGAN}
        </h3>
      </div> */}
      <div className="h-screen w-full p-4 flex flex-col justify-center mx-auto max-w-lg">
        <Outlet />
      </div>
    </div>
  );
}

import { default as _axios } from "axios";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../context";
import storage from "../utils/storage";

const API_BASE_URL = "/api";

const commonHeaders = {
  Accept: "application/json",
};

const headers = { ...commonHeaders, "Content-Type": "application/json" };

const multipart_headers = {
  ...commonHeaders,
  "Content-Type": "multipart/form-data",
};

const queryString = (params) => {
  const query = Object.keys(params)
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(
          params[k] !== undefined ? params[k] : ""
        )}`
    )
    .join("&");

  return `${query.length ? "?" : ""}${query}`;
};

function useApi() {
  const navigate = useNavigate();
  const { setCurrentUser } = useUserStore();

  const axios = _axios.create({
    baseURL: API_BASE_URL,
    timeout: 600_000,
  });

  axios.interceptors.request.use((request) => {
    request.headers.Authorization = `Bearer ${getUserToken()}`;
    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setCurrentUser(undefined);
        storage.removeItem(storage.ITEMS.currentUser);
        storage.removeItem(storage.ITEMS.jwt);
        storage.removeItem(storage.ITEMS.storageCurrentUser);
        storage.removeItem(storage.ITEMS.storageJWT);
        navigate("/");
      }

      return new Promise((_, reject) => {
        reject(error.response);
      });
    }
  );

  const getUserToken = () => storage.getItem(storage.ITEMS.jwt);

  const fetch = (url, params = {}) => {
    return axios
      .get(`${url}${queryString(params)}`, {
        headers: headers,
      })
      .then((res) => res.data);
  };

  const post = (url, data) => {
    return axios({
      method: "post",
      url: `${url}`,
      data,
      timeout: 600_000,
      headers: headers,
    }).then((res) => res.data);
  };

  const postBinary = async (url, data) => {
    return axios({
      method: "post",
      url: url,
      data: data,
      responseType: "arraybuffer",
      timeout: 600_000,      
      headers: headers,
    }).then((res) => res.data);
  };

  const getBinary = async (url, data) => {
    return axios({
      method: "get",
      url: url,
      data: data,
      responseType: "arraybuffer",
      timeout: 600_000,      
      headers: headers,
    }).then((res) => res.data);
  };

  const put = (url, data) =>
    axios.put(`${url}`, data, { headers: headers }).then((res) => res.data);

  const patch = (url, data) =>
    axios.patch(`${url}`, data, { headers: headers }).then((res) => res.data);

  const del = (url, data) => {
    return axios({
      method: "delete",
      url: `${url}`,
      data,
      timeout: 600_000,
      headers: headers,
    }).then((res) => res.data);
  };

  const postFormData = (url, formData) =>
    axios
      .post(`${url}`, formData, {
        headers: multipart_headers,
      })
      .then((res) => res.data);

  return {
    getUserToken,
    post,
    fetch,
    put,
    patch,
    del,
    postFormData,
    postBinary,
    getBinary
  };
}

export default useApi;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue, resolveSystemTenantId } from "../utils/helpers";

export default function SystemTenantApiKey() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, activeSystemTenant } = useUserStore();

  const [systemTenantApiKey, setSystemTenantApiKey] = useState({ id });

  const [systemTenantIdData, setSystemTenantIdData] = useState([]);
  // add state variables here

  useEffect(() => {
    if (isCreatingNew) return;
    setShowLoader(true);
    fetch(`admin/systemTenantApiKeyInfo/${id}/${resolveSystemTenantId(currentUser, activeSystemTenant)}`)
      .then((res) => {
        setSystemTenantApiKey(res);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve systemTenantApiKey", "error");
      })
      .finally(() => setShowLoader(false));
  }, []);

  useEffect(() => {
    fetch("admin/getSystemTenantsSimple", { sortField: "Name" })
       .then((res) => {
           setSystemTenantIdData(res);
       });
  }, [])
  // add swr calls here


  // TODO: get rid of swr call above for fk in sgb

  const handleChange = (property, value) => {
    setSystemTenantApiKey({
      ...systemTenantApiKey,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("admin/saveSystemTenantApiKey", systemTenantApiKey)
      .then((res) => {
        alert("Success", "Saved systemTenantApiKey", "success");
        navigate("/systemTenantApiKeys");
      })
      .catch((err) => {
        alert(
          "Unable to save systemTenantApiKey",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!systemTenantApiKey.systemTenantId) {
      alert("Form invalid", "System Tenant Id must have a value", "warning");
      return false;
    }
    if (!systemTenantApiKey.apiKey || !systemTenantApiKey.apiKey.trim()) {
      alert("Form invalid", "Api Key must have a value", "warning");
      return false;
    }
    // add validations here

    return true;
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !systemTenantApiKey ? "Create new systemTenantApiKey" : `Manage systemTenantApiKey ${systemTenantApiKey.id}`
        }
      />
      <GoBack display="Manage all systemTenantApiKeys" navigateTo="/systemTenantApiKeys" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <SelectInput
                label="System Tenant Id"
                options={convertToLabelValueList(systemTenantIdData)}
                onClear={() => handleChange("systemTenantId", undefined)}
                value={convertToLabelValue(systemTenantIdData?.find(x => x.id === systemTenantApiKey?.systemTenantId))}
                onChange={(systemTenantId) => handleChange("systemTenantId", systemTenantId.value)}
                isSearchable
              />
            </div>
            <div>
              <TextInput
                label="Api Key"
                value={systemTenantApiKey?.apiKey ?? ""}
                onChange={(e) => handleChange("apiKey", e.target.value)}
                required
                maxLength={100}
              />
            </div>
           {/* Put fields here */}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}

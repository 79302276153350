import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

function BarChart({ data, max, ticks = 1, hideLabel = false }) {
  const maxValue = max || Math.max(...data.map((bar) => bar.value));

  // const sorted = data.sort((a, b) => b.value - a.value);

  return (
    <Bar
      options={{
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        scales: {
          x: {
            ticks: {
              stepSize: ticks,
            },
          },
          y: {
            display: !hideLabel
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      data={{
        datasets: [
          {
            data: data.map((x) => ({
              x: x.value,
              y: `${x.label} (${x.value})`,
            })),
            borderColor: "rgb(54, 162, 235)",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
          },
        ],
      }}
    />
  );
}

export default BarChart;

import { Switch } from "@headlessui/react";
import { Label } from ".";
import { classNames } from "../../utils/helpers";

export default function ToggleInput({ label, enabled, setEnabled }) {
  return (
    <Switch.Group>
      <div className="">
        {/* Label/required asterisk */}
        {label && <Label label={label}/>}
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? "bg-primaryColor" : "bg-gray-300",
            label ? "mt-1" : "",
            "relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none"
          )}
        >
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}

import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
  CheckIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { TextInput } from ".";
import { useLoaderStore, useUserStore } from "../../context";
import { useAlert, useApi } from "../../hooks";
import constants from "../../utils/constants";
import dateHelpers from "../../utils/dateHelpers";
import { formatPhoneNumber, resolveSystemTenantId, switchSortDirection, userHasRole } from "../../utils/helpers";
import _ from "lodash";
import AsyncSelect from "react-select/async";

export default function SystemTenantTypeahead({value, onChange, limitToAccount}) {

  const { post, fetch } = useApi();
  const alert = useAlert();  
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {

  }, []); 

  const loadSystemTenants = (typedValue, callback) => {
    if (!typedValue || !typedValue.length || typedValue.length < 2) {
      return;
    }

    post("Admin/SystemTenantTypeahead", {Name: typedValue, isExact: false, limitToAccountSystemTenants: limitToAccount})
    .then((response) =>  {
      callback(response)
    })
    .catch(() => {
      alert("Server error", "Could not retrieve system tenant(s)", "error");
      callback([]);
    });

  }

  return (
    <>
    <AsyncSelect 
    loadOptions={loadSystemTenants}
    onInputChange={(e) => setInputValue(e)}
    onChange={onChange}
    noOptionsMessage={() => (!inputValue || inputValue.length <= 1) ? "Start typing to search for system tenants" : "No system tenants found"}
    value={value}
    isClearable
    className="mt-1"
    />
    </>
  );
}
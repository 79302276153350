import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { PrimaryButton } from "../../components/Buttons";
import { ToggleInput } from "../../components/Inputs";
import { useLoaderStore, useUserStore } from "../../context";
import { useAlert, useApi } from "../../hooks";
import constants from "../../utils/constants";
import { classNames, formatCurrency } from "../../utils/helpers";
import React from "react";

const { DURATION_TYPES } = constants;

export default function Products({
  products,
  productDetails,
  currentSubscription,
  defaultPaymentMethod,
  selectedDurationType,
  setSelectedDurationType,
  refreshData
}) {
  const alert = useAlert();
  const { setShowLoader } = useLoaderStore();
  const { activeSystemTenant, activeAccount } = useUserStore();
  const { fetch, post } = useApi();

  const purchaseSubscription = (productId, productPrice) => {
    if (currentSubscription || !isChangeSubscriptionValid(productPrice)) return;

    setShowLoader(true);
    let payload = {
      ProductId: productId,
      Qty: 1,
      Autorenew: true,
      NotifyTenantUsers: true,
    };
    post(
      `admin/PurchaseMargotProduct/${activeAccount.id}/${activeSystemTenant?.id}`, payload
    )
      .then(() => {
        alert(
          "Successfully purchased subscription",
          "Your subscription has been purchased",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        console.error(err);
        alert(
          "Could not purchase subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  const upgradeSubscription = (upgradeProductId, productPrice) => {
    if (!currentSubscription || !isChangeSubscriptionValid(productPrice)) return;

    setShowLoader(true);
    let payload = {
      OwnedProductId: currentSubscription.ownedProductId,
      UpgradeProductId: upgradeProductId,
      PaymentMethodId: defaultPaymentMethod.id,
      Qty: 1,
      Autorenew: true,
      NotifyTenantUsers: true,
      // StripeCustomerId: stripeCustomerId, // GETTING FROM SERVER
      // TenantId: tenantId, // GETTING FROM SERVER
    };
    post(`admin/UpgradeSystemAccountSubscriptionNow/${activeAccount.id}`, payload).then(() => {
        alert(
          "Successfully upgraded subscription",
          "Your subscription has been upgraded",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        console.error(err);
        alert(
          "Could not upgrade subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const switchSubscription = (renewalProductId, productPrice) => {
    if (!currentSubscription || !isChangeSubscriptionValid(productPrice)) return;

    setShowLoader(true);
    post(
      `admin/ChangeSystemAccountSubscription/${currentSubscription.ownedProductId}/${renewalProductId}`
    )
      .then(() => {
        alert(
          "Successfully switched subscription",
          "Your subscription has been changed",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        alert(
          "Could not switch subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isChangeSubscriptionValid = (productPrice) => {
    if (defaultPaymentMethod === undefined && productPrice !== 0) {
      alert(
        "No default payment method",
        "Create a payment method and set it as your default",
        "warning"
      );
      return false;
    }
    return true;
  };

  const resolveButtonText = (isCurrentSubscription, canUpgrade, showResetRenewSubscription) => {
    if (isCurrentSubscription && showResetRenewSubscription) return "Set as renewal plan";
    if (isCurrentSubscription) return "Your current plan";
    if (!currentSubscription) return "Purchase";
    return canUpgrade
      ? "Upgrade"
      : "Switch"
  }

  const resolveSystemTenantLimit = (details) => {
    if (typeof details.numberOfSystemTenants === "number"){
      if (details.numberOfSystemTenants === 1) return `${details.numberOfSystemTenants} System Tenant`
      return `Up to ${details.numberOfSystemTenants} System Tenants`;
    }
    return "Unlimited System Tenants";
  }

  const resolveSubText = (productPriceInPennies, canUpgrade, isCurrentSubscription, showResetRenewSubscription) => {
    if ( isCurrentSubscription && showResetRenewSubscription ) 
      return "Your CurrentPlan";
    else if ( isCurrentSubscription && !showResetRenewSubscription ) 
      return null;
    else if (canUpgrade || productPriceInPennies === currentSubscription?.priceInPennies) 
      return "You will be immediately placed onto this plan";
    else 
      return "You will be placed onto this plan at the end of your current subscription period";
  }

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
      <div className="flex justify-center mb-12 gap-x-2">
        <ToggleInput
          enabled={selectedDurationType === "Years"}
          setEnabled={() =>
            setSelectedDurationType((x) => (x === "Years" ? "Months" : "Years"))
          }
        />
        {selectedDurationType === "Years" ? "Annual" : "Monthly"}
      </div>
      <div className="grid grid-cols-1 gap-y-16 divide-y divide-gray-200 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
        {products
          .filter(
            (x) =>
              x.durationType === selectedDurationType//DURATION_TYPES.find(d => d.label === selectedDurationType)?.value //DURATION_TYPES.BY_NAME[selectedDurationType]
          )
          .sort((a, b) => a.price - b.price)
          .map((x, i) => {
            const canUpgrade =
              currentSubscription?.price !== undefined &&
              x.price > currentSubscription.price;
            const isCurrentSubscription = currentSubscription 
              && currentSubscription?.productId === x.productId;
            const details = productDetails && productDetails.length && productDetails.find(p => p.productId === x.productId);
            const showResetRenewSubscription = isCurrentSubscription && currentSubscription?.productId !== currentSubscription?.renewalProductId;
            return (
              <div
                key={x.productId}
                className="pt-16 lg:px-8 lg:pt-0 xl:px-14 list-items"
              >
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  {x.name}
                </h3>
                <p className="text-sm leading-6 text-gray-500">
                  {x.description}
                </p>
                <p className="mt-6 mb-12 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {x.price === 0
                      ? "Free"
                      : formatCurrency(x.price, true)}
                  </span>
                  {x.price > 0 && (
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      /{selectedDurationType === "Years" ? "year" : "month"}
                    </span>
                  )}
                </p>

                <PrimaryButton
                  text={resolveButtonText(isCurrentSubscription, canUpgrade, showResetRenewSubscription)}
                  disabled={isCurrentSubscription && !showResetRenewSubscription}
                  onClick={() => {
                    if ((isCurrentSubscription && !showResetRenewSubscription) || x.productId === undefined)
                      return;
                    else if (!currentSubscription) purchaseSubscription(x.productId, x.price);
                    else if (canUpgrade) upgradeSubscription(x.productId, x.price);
                    else switchSubscription(x.productId, x.price);
                  }}
                />

                <div
                  className={classNames(
                    "flex h-4 justify-end mt-0.5 text-xs text-gray-400",
                    isCurrentSubscription && !showResetRenewSubscription ? "invisible" : ""
                  )}
                >
                  <div className="w-3/4 text-right">
                    {resolveSubText(x.price, canUpgrade, isCurrentSubscription, showResetRenewSubscription)}
                  </div>
                </div>
                {details && (
                  <ul
                    role="list"
                    className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <CheckCircleIcon className="h-6 w-5 flex-none text-primaryColor" />
                      {resolveSystemTenantLimit(details)}
                    </li>
                    <li className="flex gap-x-3">
                      <CheckCircleIcon className="h-6 w-5 flex-none text-primaryColor" />
                      {`${details.transactionFeeAmount}% Transaction fee `}
                    </li>
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

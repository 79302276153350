import { useState } from "react";
import { getCountries, isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { PasswordInput, SelectInput, TelephoneInput, TextInput } from "../components/Inputs";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { useRegistrationStore } from "../context";
import { UserRegistrationForm, SystemTenantRegistrationForm, AccountRegistrationForm } from "../components/Forms";
import { RegistrationStepsHeader } from "../components/Displays";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";

export default function Register() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { registrationUser, regisreationSysTenant, registrationAccount, currentStep } = useRegistrationStore();

  const handleSubmit = (sysTenant) => {
    if (!registrationUser || !sysTenant) return;

    setShowLoader(true);

    post("public/register", {
      user: registrationUser,
      account: registrationAccount,
      systemTenant: sysTenant
    } )
    .then((res) => {
      alert(
        "Successfully Registered",
        "Check your inbox to confirm your email",
        "success",
        false
      );
      navigate("/");
    })
    .catch((err) => {
      alert("Unsuccessful", "Unable to register your account", "error");
      console.error(err);
    })
    .finally(() => {
      setShowLoader(false);
    });
  };

  const ResolveCurrentRegistrationStep = () => {
    switch(currentStep) {
      case constants.REGISTRATION_STEPS.ACCOUNT:
        return <AccountRegistrationForm />;
      case constants.REGISTRATION_STEPS.SYSTEM_TENANT:
        return (<SystemTenantRegistrationForm 
                  handleSubmitCallback={handleSubmit}
                />);
      default:
        return <UserRegistrationForm />;
    }
  }

  return (
    <>
      <div
        className={
          "h-11 md:h-15 text-3xl font-bold text-white bg-primaryColor flex place-items-center justify-center md:justify-start"
        }
      >
        <h3 className={"ml-8"}>{constants.APP_NAME}</h3>
      </div>
      <RegistrationStepsHeader />
      <div>
        <h5 className="mt-1 mb-6 ml-2">
          <Link to="/" className="flex flex-row text-primaryColor hover:text-primaryColor/50">
            <ArrowLongLeftIcon className="w-5 mr-2" />
            Back To Login
          </Link>
        </h5>
      </div>
      <div className="h-screen w-full p-4 flex flex-col mx-auto max-w-lg">
        {ResolveCurrentRegistrationStep()}
      </div>
    </>
  );
}

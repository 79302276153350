import { XCircleIcon } from "@heroicons/react/24/solid";

export default function ActiveFilter({ display, value, onClear }) {
  return value !== undefined ? (
    <div className="flex items-center bg-gray-100 px-3 py-2 text-xs">
      {display}:<div className="ml-1 font-semibold">{value}</div>
      <XCircleIcon className="ml-3 w-4 cursor-pointer" onClick={onClear} />
    </div>
  ) : (
    <></>
  );
}

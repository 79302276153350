import { useState } from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { CheckboxInput, PasswordInput, TextInput } from "../components/Inputs";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import storage from "../utils/storage";

export default function Login() {
  const alert = useAlert();
  const { post } = useApi();

  const [username, setUsername] = useState(
    storage.getItem(storage.ITEMS.username) ?? ""
  );
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const { login } = useUserStore();
  const { setShowLoader } = useLoaderStore();

  const handleSignIn = async () => {
    if (!isValid()) return;
    setShowLoader(true);

    await post("public/login", { username, password })
      .then((res) => {
        let user = res.user || {};
        let token = res?.token || undefined;
        let systemTenants = res?.systemTenants || undefined;
        login(user, token, rememberMe, systemTenants);
      })
      .catch((err) => {
        alert("Unsuccessful login attempt", err.data.message || "Invalid credentials", "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!username || !username.trim() || username.trim().length <= 1) {
      alert("Invalid input", "Please enter a username", "warning");
      return false;
    }

    if (!password || !password.trim()) {
      alert("Invalid input", "Please enter a password", "warning");
      return false;
    }

    return true;
  };

  return (
    <>
      <h1 className="text-3xl font-bold text-primaryColor">
        Sign In to {constants.APP_NAME}
      </h1>
      <h5 className="mt-1 mb-6">
        Or{" "}
        <Link
          to="/register"
          className="text-primaryColor hover:text-primaryColor/50"
        >
          create an account
        </Link>
      </h5>

      <div className="w-full space-y-6">
        <div>
          <TextInput
            label="Username or Email"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <PasswordInput
            label="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSignIn();
            }}
          />
          <div className="flex justify-between mt-2">
            <CheckboxInput
              label="Remember me"
              name="rememberMe"
              checked={rememberMe}
              onChange={() => setRememberMe(x => !x)}
            />
            <Link
              to="/forgotPassword"
              className="text-primaryColor hover:text-primaryColor/50 text-sm"
            >
              Forgot my password
            </Link>
          </div>
        </div>
        <PrimaryButton text="Sign In" onClick={handleSignIn} />
      </div>
    </>
  );
}
